import React from "react"
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Divider } from "@material-ui/core";
import Logo from "../../images/websiteheader.jpg"
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { NavLink } from "react-router-dom";
import Email from 'mdi-material-ui/Email';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';
import Backend from "../Backend";
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog.js"
import { red } from '@material-ui/core/colors';



const useStyles = makeStyles(theme => ({

    mainContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        backgroundColor: "whitesmoke",
        '@media only screen and (max-width: 950px)': {
            backgroundColor: "white",
        },
        '@media only screen and (max-width: 950px) and (max-height: 500px)': {
            backgroundColor: "white",
            height: "auto"

        }

    },
    forgetPassContainer: {
        width: "500px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        boxShadow: "2px 3px 6px 0px rgba(171,164,171,1)",
        borderRadius: "5px",
        '@media only screen and (max-width: 950px)': {
            width: "100%",
            height: "auto",
            boxShadow: "0px 0px 0px 0px rgba(171,164,171,1)",
            borderRadius: "0px",

        }

    },
    imgContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "65%"

    },
    image: {
        width: "100%",
        '@media only screen and (max-width: 950px)':{
            
        },
        '@media only screen and (max-width: 950px) and (max-height: 500px)': {
            
        }
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        width: "90%",
        height: "25%;",
        '& > *': {
            marginBottom: "40px",
        }
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center'
    },
    load: {
        width: "100%",
        '@media only screen and (max-width: 700px)': {
            width: "100%",
            marginBottom: window.innerHeight / 4 + "px"

        }
    }

}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#005D98',
        },
        secondary: red
    },
})
function ForgetPassword(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        email: "",
    });
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.value });

    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            let response = await Backend.resetPassEmail(state.email)
            if (response === 400) throw response
            props.onDisplayAlert("Password has been reseted.")

        } catch (error) {
            props.onDisplayError("Account with that email does not exist", error)
        }

    }


    return (
        <div className={classes.mainContainer}>
            <form className={classes.forgetPassContainer} onSubmit={handleSubmit}>
                <div className={classes.imgContainer}>
                    <img src={Logo} alt="logo" className={classes.image} />
                </div>
                <div style={{ width: '100%', marginBottom: "30px" }}>
                    <Divider />
                </div>
                <span style={{ fontSize: "30px" }}>Forgot Password</span>
                <div className={classes.formContainer}>
                    <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Email"
                        type='email'
                        fullWidth
                        required
                        onChange={handleChange('email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                    />


                </div>

                <div style={{ width: "90%", "display": "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <ThemeProvider theme={theme}>
                        <NavLink to={"/login"} style={{ marginRight: "10px" }}>
                            <Button variant="contained" color="secondary" style={{ width: "100%", marginBottom: "10px", }}>
                                <ChevronLeft />
                                <span style={{ marginLeft: "10px" }}>Go Back to Login</span>
                            </Button>
                        </NavLink>
                        <Button type="submit" variant="contained" color="primary" style={{ width: "20%", marginBottom: "10px" }}>
                            Send
                         </Button>
                    </ThemeProvider>

                </div>

            </form>
            <div>
            </div>
        </div>
    ) // end return 

}// end forgetPassword

export default withAlertAndProgressDialog(ForgetPassword)