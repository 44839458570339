import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PostedJobs from './jobs/PostedJobs';
import Settings from './settings/Settings';
import PaymentMethods from './paymentmethods/PaymentMethods';
import BuyPosts from './buyposts/BuyPosts';
import SendNotifications from "./admin/notifications"
import { setUser } from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
// import RemoveUser from "./admin/remove"
import AddAdmin from './admin/addAdmins'
import ViewApplicants from './applicants/ViewApplicants'
import ListBusiness from './ViewRecruiter/ListBusiness';
import BusinessRoute from './privateRoute/BusinessRoute';
import AdminRoute from './privateRoute/adminRoute';


function RouteHandler() {
  const user = useSelector(state => state.user);
  return (
    <Switch>
      {/* COMMON ROUTE  */}
      <Route exact path='/settings' component={Settings} />
      {/*Recruiter will see these routes*/}
      <BusinessRoute exact path='/posted_jobs' component={PostedJobs} />
      <BusinessRoute exact path='/payment_methods' component={PaymentMethods} />
      <BusinessRoute exact path='/buy_posts' component={BuyPosts} />
      <BusinessRoute exact path='/view_applicants' component={ViewApplicants} />
      {/* // <Route path='/posted_jobs' component={PostedJobs} /> */}
      {/* <Route path='/settings' component={Settings} />
      <Route path='/payment_methods' component={PaymentMethods} />
      <Route path='/buy_posts' component={BuyPosts} />
      <Route path='/view_applicants' component={ViewApplicants} /> */}
      <Route exact path='/' render={() => <Redirect to={`${user?.admin ? '/send_notifications' : '/posted_jobs'}`} />} />

      {/*Admin will see these routes*/}
      <AdminRoute path='/send_notifications' component={SendNotifications} />
      <AdminRoute path='/add_admins' component={AddAdmin} />
      <AdminRoute path='/view-business' component={ListBusiness} />
      {/* {user?.admin && <Route path='/send_notifications' component={SendNotifications} />} */}
      {/* {user?.admin && <Route path='/remove' component={RemoveUser} />} */}
      {/* {user?.admin && <Route path='/add_admins' component={AddAdmin} />}
      {user?.admin && <Route path='/view-business' component={ListBusiness} />}*/}
      {/* {user?.admin && <Route exact path='/' render={() => <Redirect to='/send_notifications' />} />} */}

    </Switch>
  )
}

export default RouteHandler;