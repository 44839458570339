import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Divider,
  Grid,
  Fab,
  ButtonGroup,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import MapMarker from "mdi-material-ui/MapMarker";
import CreateJobDialog from "./CreateJobDialog";
import Backend from "../Backend";
import { useSelector } from "react-redux";
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import { useHistory, Link } from "react-router-dom";

// import { withLeaflet } from 'react-leaflet';
// import AutoSearch from 'react-leaflet-google-places-searchbox';
// const unfoldIcon = require('../../images/coverShiftz.png');
// import unfoldIcon from "../../images/coverShiftz.png"
// import { ReactLeafletSearch } from "react-leaflet-search";
// import Suggesst from './SuggestAddress';
import SimpleExample from "./SuggestAddress";
// import { Map, TileLayer, Popup, withLeaflet } from "react-leaflet";

import { OpenStreetMapProvider } from "leaflet-geosearch";
import * as GeoSearch from "leaflet-geosearch";
import MyMap from "./GeoSearch";
import { capitalizeFirstLetter } from "../../utils/functions";
import "./createJob.css"
import JobDescriptionDailogue from "./JobDescriptionDailogue";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#005D98",
    },
    secondary: red,
  },
});
function PostedJobs(props) {
  const history = useHistory();
  const dateFns = new DateFnsUtils();
  const business = useSelector((state) => state.business);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    showDialog: false,
  });
  const [repost, setRepost] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobTobeReposted, setJobToBeReposted] = useState({});
  const [seeMore, setSeeMore] = useState(false)
  const [currentDescription, setCurrentDescription] = useState("")
  const user = useSelector((state) => state.user);
  // const ReactLeafletSearchComponent = withLeaflet(ReactLeafletSearch);
  const provider = new OpenStreetMapProvider();

  const closeDialog = () => {
    setState({ ...state, showDialog: false });
  };

  const openDialog = () => {
    setState({ ...state, showDialog: true });
    setRepost(false);
  };

  const handleRemoveJob = (jobId) => async (e) => {
    setLoading(true);
    try {
      let response = await Backend.business_CancelJobpost(jobId);
      let json = await response.json();
      if (!json.success) throw json;
      setLoading(false);
      props.onDisplayAlert(json.message, "Success");
      setJobs(jobs.filter((item) => item._id !== jobId));
    } catch (err) {
      setLoading(false);
      props.onDisplayError(
        err.message || "An unexpected erorr has occured",
        "Error"
      );
    }
  };

  const handleRepost = (job) => (e) => {
    setJobToBeReposted(job);
    setRepost(true);
    setState({ ...state, showDialog: true });
  };
  useEffect(() => {
    if (user?.admin) {
      history.push("/");
    }
  }, [user]);
  const handleSeeMore = (description) => {
    setCurrentDescription(description)
    setSeeMore(true)
  }
  const handleCloseModal = () => {
    setSeeMore(false)
  }
  const mapJobs = (status) => (shift, i) => {

    // console.log(shift.status, "status", shift.job_type)
    // switch (shift.job_type) {
    //     case "part-time":
    //         shift.job_type = "Part-Time"
    //         break;
    //     case "full-time":
    //         shift.job_type = "Full-Time"
    //         break;
    //     default:
    //         break
    // }

    const checkDescription = (description) => {
      // if (!seeMore && description.length > 400) {
      //   // let span = document.createElement("span").innerHTML = " See more"
      //   // span.innerHTML = await " See More"
      //   // await span.
      //   const string = description.slice(0, 400)
      //   return string
      // }
      const string = description.slice(0, 200)
      return string
    }
    // let showChar = 100;
    // let ellipsesText = "...";
    // let moreText = "more";
    // let lessText = "less";
    if (shift.status === status) {
      // https://www.openstreetmap.org/search?query
      return (
        <Grid item xs={12} md={6} key={shift._id} style={AddressEllipsis}>
          <Paper style={{ padding: 10, height: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6">{shift.title ?? "N/A"}</Typography>
              &nbsp;&nbsp;
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <MapMarker />
                &nbsp;
                <Typography style={{ marginRight: "5px" }} variant="caption">
                  {shift.location ?? "Unknown"}
                </Typography>
              </div>
              {/* {shift?.hourly_rate && ( */}
              <div style={{ marginLeft: "auto" }}>
                <Typography variant="caption">
                  {shift?.job_type == "annual_salary" &&
                    shift?.annual_salary &&
                    "Rate: $" + shift?.annual_salary + "/hr"}
                  {shift?.job_type == "pay-based-on-experience" &&
                    "Pay Based On Experience"}
                  {shift?.job_type == "part-time" &&
                    shift?.hourly_rate &&
                    "Rate: $" + shift?.hourly_rate + "/hr"}
                  {shift?.job_type == "full-time" &&
                    shift?.hourly_rate &&
                    "Rate: $" + shift?.hourly_rate + "/hr"}
                </Typography>
              </div>
              {/* )} */}
            </div>
            <Divider className="divider-margin" />
            <Grid container>
              <Grid item xs={10} md={12} lg={9}>
                <Typography variant="caption">
                  <b> Description: </b>
                  <p>
                    {shift.description?.task ? <pre style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}> {checkDescription(shift.description?.task)} {!seeMore && shift.description?.task.length > 100 && <> ... &nbsp; <a id="seeMore" onClick={() => handleSeeMore(shift.description?.task)} >See more</a></>} </pre> : "Unknown"}
                  </p>
                </Typography>
                <Typography variant="caption">
                  <b> Benefits:</b>
                  <p>
                    {shift.benefits ? <pre style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}> {checkDescription(shift.benefits)} {!seeMore && shift.benefits.length > 100 && <> ... &nbsp; <a id="seeMore" onClick={() => handleSeeMore(shift.benefits)} >See more</a></>} </pre> : "Unknown"}
                  </p>
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: "8px" }}>
                <Typography style={{ fontWeight: "bold" }} variant="caption">
                  Type:&nbsp;
                </Typography>
                <Typography variant="caption">
                  {capitalizeFirstLetter(shift.job_type) ?? "shift"}
                </Typography>
                <br />
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="caption"
                  color="primary"
                >
                  {shift?.remote && "Remote Job"}
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={0}>
              <Grid item xs={9} md={12} lg={9}>
                <Typography style={{ fontWeight: "bold" }} variant="caption">
                  Category:&nbsp;
                </Typography>
                <Typography variant="caption">
                  {shift.category ?? "Unknown"}
                </Typography>
                <br />
                <Typography style={{ fontWeight: "bold" }} variant="caption">
                  Attire:&nbsp;
                </Typography>
                <Typography variant="caption">
                  <p>
                    {shift.description?.attire ? <pre style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}> {checkDescription(shift.description?.attire)} {!seeMore && shift.description?.attire.length > 100 && <> ... &nbsp; <a id="seeMore" onClick={() => handleSeeMore(shift.description?.attire)} >See more</a></>} </pre> : "Unknown"}
                  </p>
                  {/* {shift.description?.attire ?? "N/A"} */}
                </Typography>
                <br />
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: "0px" }}>
                <Typography style={{ fontWeight: "bold" }} variant="caption">
                  Post Date:&nbsp;
                </Typography>
                <Typography variant="caption">
                  {shift.start_time
                    ? dateFns
                      .format(dateFns.date(shift.start_time), "MM/dd/y")
                      .toLocaleString()
                    : "Unknown"}
                </Typography>
                <br />
                <Typography style={{ fontWeight: "bold" }} variant="caption">
                  Expire Date:&nbsp;
                </Typography>
                <Typography variant="caption">
                  {shift.end_time
                    ? dateFns
                      .format(dateFns.date(shift.end_time), "MM/dd/y")
                      .toLocaleString()
                    : "Unknown"}
                </Typography>
              </Grid>
            </Grid>
            {/* </div> */}
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <ButtonGroup variant="outlined">
                  {status === "expired" && (
                    <Button color="primary" onClick={handleRepost(shift)}>
                      Repost Job
                    </Button>
                  )}
                  {/*{status !== 'expired' &&*/}
                  {/*<Button color='primary' onClick={routeChange}>View Applicants</Button>}*/}
                  {status !== "expired" && (
                    <Button
                      color="primary"
                      onClick={goToApplicants(
                        "/view_applicants",
                        shift.workers_applied
                      )}
                    >
                      {status !== "expired" && "View Applicants"}
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    onClick={handleRemoveJob(shift._id)}
                  >
                    Remove Job
                  </Button>
                </ButtonGroup>
                {status !== "expired" && (
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Remove Job - post will be removed from the app. Also it will
                    not appear in your Expired Job section
                  </span>
                )}
              </ThemeProvider>
            </div>
          </Paper>

        </Grid>
      );
    }
  };

  const goToApplicants = (path, state) => async (e) => {
    history.push(path, state);
  };

  const getJobPosts = async () => {
    setLoading(true);
    try {
      let data = await Backend.business_GetAllJobPosts(business._id);
      setJobs(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      props.onDisplayError(
        err.message || "An unexpected error has occurred.",
        err
      );
    }
  };

  const addJobPost = (job) => setJobs([...jobs, job]);

  useEffect(() => {
    if (business) getJobPosts();
  }, [business]);
  const AddressEllipsis = {
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // // width: '200px',
    // maxHeight: "350px",
  };
  return (
    <Container>
      <Paper style={{ padding: 10 }}>
        {loading && <LinearProgress />}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Active Jobs</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="caption">
              Remaining Posts: {business?.posts_remaining}
            </Typography>
            &nbsp;&nbsp;
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ThemeProvider theme={theme}>
                <Fab size="small" color="primary" onClick={openDialog}>
                  +
                </Fab>
              </ThemeProvider>
              <span style={{ marginTop: "5px", fontSize: "10px" }}>
                {" "}
                Add New Job
              </span>
            </div>
          </div>
        </div>
        <Divider className="divider-margin" />
        {jobs.length === 0 && <Typography>You have no active jobs.</Typography>}
        {jobs.length > 0 && (
          <Grid container spacing={3} alignItems="stretch">
            {jobs.map(mapJobs("open"))}
          </Grid>
        )}
        <br />
        <Typography variant="h6">Expired Jobs</Typography>
        <Divider className="divider-margin" />

        {/* <MapContainer
                    // center={this.props.leafletStore.centerLatlng}
                    // zoom={this.props.leafletStore.zoom}
                    // zoomControl={false}
                    // onClick={this.onMapClick}
                    ref='map'
                    className="markercluster-map map"
                    center={[51.0, 19.0]}
                // zoom={4}
                // maxZoom={18}
                // onViewportChanged={viewport => this.props.leafletStore.setViewportChange(viewport)}
                // style={styleObj}    
                >
                    <AutoSearch
                        unfoldIcon={unfoldIcon}
                        handleClickSearchIcon={() => this.props.leafletStore.setShowMenu(true)}
                        updateLocation={(lat, lng) => this.props.leafletStore.setMakerPosition({ lat, lng })}
                    />
                </MapContainer> */}
        {/* <ReactLeafletSearchComponent
                    customProvider={this.provider}
                    position="topleft"
                    inputPlaceholder="Custom placeholder"
                    search={[33.100745405144245, 46.48315429687501]}
                    showMarker={true}
                    zoom={5}
                    showPopup={true}
                    popUp={this.customPopup}
                    closeResultsOnClick={true}
                    openSearchOnLoad={true}
                // // these searchbounds would limit results to only Turkey.
                // searchBounds={[
                //   [33.100745405144245, 46.48315429687501],
                //   [44.55916341529184, 24.510498046875]
                // ]}
                // providerOptions={{region: 'tr'}}

                // default provider OpenStreetMap
                // provider="BingMap"
                // providerKey="AhkdlcKxeOnNCJ1wRIPmrOXLxtEHDvuWUZhiT4GYfWgfxLthOYXs5lUMqWjQmc27"
                /> */}
        {/* <SimpleExample /> */}
        {/* <MyMap /> */}
        {jobs.length === 0 && (
          <Typography>You have no expired jobs.</Typography>
        )}
        {jobs.length > 0 && (
          <Grid container spacing={3} alignItems="stretch">
            {jobs.map(mapJobs("expired"))}
          </Grid>
        )}
      </Paper>
      <JobDescriptionDailogue seeMore={seeMore} handleCloseModal={handleCloseModal} description={currentDescription} />
      <CreateJobDialog
        open={state.showDialog}
        onClose={closeDialog}
        addJobPost={addJobPost}
        repost={repost}
        job={jobTobeReposted}
      />
    </Container>
  );
}

export default withAlertAndProgressDialog(PostedJobs);
