import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CardForm from './CardForm';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Backend from '../Backend';
import CreditCard from './CreditCard';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';
import stripeImg from '../../images/powered_by_stripe.png';

function PaymentMethods(props) {
  const [stripeAccount, setStripeAccount] = useState(null);
  const [loading, setLoading] = useState(false);

  const getStripeAccount = async () => {
    setLoading(true);
    try {
      let response = await Backend.business_GetStripeAccount();
      let data = await response.json();
      setStripeAccount(data.customer);
      setLoading(false);
    } catch (err) {
      props.onDisplayError(err.message || 'An error has occurred.', err);
      setLoading(false);
    }
  }

  const mapCards = source => {
    // if (source.object === 'card') {
    return (
      <Grid item key={source.id}>
        {/* {console.log(source, "mapfunc")} */}
        <CreditCard
          card={source.card}
          isDefault={source.id === stripeAccount.default_source}
          getStripeAccount={getStripeAccount}
        />
      </Grid>
    )
    // }
  }

  useEffect(() => {
    getStripeAccount();
  }, []);

  return (
    <Container>
      <Paper style={{ padding: 10 }}>
        {loading && <LinearProgress />}
        <Typography variant='h6' component='span'>Card Details</Typography>
        <Divider className='divider-margin' />
        <StripeProvider apiKey='pk_test_51HpYTjKN3Va3uTKDBdIr34pFhuNcQFnAwOuqIkOdtrbIwyeN4Ka3IrQ8klXBsdUXMdBQSBGSWhAyIuy2GfNV6BGL00BpAeQAMB'>
          {/* pk_live_aXC66rBLLpZxi4x0COnrH5ZR00pTCz0bRi */}
          <Elements>
            <CardForm getStripeAccount={getStripeAccount} />
          </Elements>
        </StripeProvider>
        {stripeAccount && (
          <React.Fragment>
            <br />
            <Typography variant='h6'>Current Credit Cards</Typography>
            <Divider className='divider-margin' />
            {console.log(stripeAccount.sources, "sources")}
            {stripeAccount.sources.data.length === 0 ? <Typography>No credit cards on file. Add one through the form above.</Typography> : <Grid container spacing={1} justify='center'>{stripeAccount.sources.data.map(mapCards)}</Grid>}
          </React.Fragment>
        )}
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <img src={stripeImg} height={50} alt="stripeIcon" />
        </div>
      </Paper>
    </Container>
  )
}

export default withAlertAndProgressDialog(PaymentMethods);