import { SET_USER, SET_BUSINESS } from "./actionTypes";

export default function(state = {
    user: null,
    business: null
}, action) {
    switch(action.type) {
        case SET_USER:
            return { ...state, user: action.payload };
        case SET_BUSINESS:
            return { ...state, business: action.payload };
        default:
            return state;
    }
}