export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function addMinutesInDate(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}
export function updateEndTime(dateString) {
    var date = new Date(dateString);
    date.setHours(0, 0, 0);   // Set hours, minutes and seconds
    return date.toString();
}