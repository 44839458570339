import React, { useState } from 'react';
import { Paper, Typography, Divider, Button, ButtonGroup, ThemeProvider, createMuiTheme } from '@material-ui/core';
import AccountCircle from 'mdi-material-ui/AccountCircle';
import { grey } from '@material-ui/core/colors';
import EditProfileDialog from "./EditProfileDialog"
import ChangePasswordDialog from './ChangePasswordDialog'
import { useSelector, useDispatch } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Backend from '../Backend';
import { setBusiness } from '../../redux/actions';
import { red } from '@material-ui/core/colors';
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#005D98',
        },
        secondary: red
    },
})

function Settings(props) {
    const businessInfo = useSelector(state => state.business);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        showEditProfileDialog: false,
        showChangePasswordDialog: false
    })

    const imageSelected = async e => {
        let form = new FormData();
        form.append('avatar', e.target.files[0]);
        setLoading(true);
        try {
            let response = await Backend.business_UploadAvatar(form);
            let data = await response.json();
            if (!data.success) throw data;
            dispatch(setBusiness({ ...businessInfo, avatar_uri: data.imageUrl }));
            setLoading(false);
            props.onDisplayAlert("Image successfully changed", "Success")
        } catch (err) {
            props.onDisplayError(err.message || 'An error has occurred.', err);
            setLoading(false);
        }
    }

    const closeDialog = type => event => {
        setState({ ...state, [type]: false })
    };

    const openDialog = type => event => {
        setState({ ...state, [type]: true })
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {businessInfo && (
                <Paper style={{ width: 500 }}>
                    {loading && <LinearProgress />}
                    <div style={{ backgroundColor: grey[500], display: 'flex', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10 }}>
                            {businessInfo.avatar_uri ? <img src={businessInfo.avatar_uri} alt='Avatar' style={{ height: 200, width: 200, borderRadius: '50%' }} /> : <AccountCircle style={{ fontSize: 200 }} />}
                            <br />
                            <input type='file' accept='image/*' onChange={imageSelected} />
                        </div>
                    </div>
                    <div style={{ padding: 10 }}>
                        <Typography>General Info</Typography>
                        <Divider className='divider-margin' />
                        <table className='settings-table'>
                            <tbody>
                                <tr>
                                    <th>Street Address:</th>
                                    <td>{businessInfo.address}</td>
                                </tr>
                                <tr>
                                    <th>Apt, Suite #:</th>
                                    <td>{businessInfo.address2}</td>
                                </tr>
                                <tr>
                                    <th>City:</th>
                                    <td>{businessInfo.city}</td>
                                </tr>
                                <tr>
                                    <th>State:</th>
                                    <td>{businessInfo.state}</td>
                                </tr>
                                <tr>
                                    <th>Zip Code:</th>
                                    <td>{businessInfo.zip}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <Typography>Legal Representative Info</Typography>
                        <Divider className='divider-margin' />
                        <table className='settings-table'>
                            <tbody>
                                <tr>
                                    <th>First Name:</th>
                                    <td>{businessInfo.manager_fname}</td>
                                </tr>
                                <tr>
                                    <th>Last Name:</th>
                                    <td>{businessInfo.manager_lname}</td>
                                </tr>
                                <tr>
                                    <th>Phone Number:</th>
                                    <td>{businessInfo.phone}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div style={{ textAlign: 'center', margin: 10, }}>
                        <ThemeProvider theme={theme}>
                            <ButtonGroup variant='outlined' color='primary'>
                                <Button onClick={openDialog('showChangePasswordDialog')}>Change Password</Button>
                                <Button onClick={openDialog('showEditProfileDialog')}>Edit Profile</Button>
                            </ButtonGroup>
                        </ThemeProvider>
                    </div>
                    <EditProfileDialog open={state.showEditProfileDialog} onClose={closeDialog('showEditProfileDialog')} />
                    <ChangePasswordDialog open={state.showChangePasswordDialog} onClose={closeDialog('showChangePasswordDialog')} />
                </Paper>
            )}
        </div>
    )
}

export default withAlertAndProgressDialog(Settings);