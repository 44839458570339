import React, { useState } from 'react';
import './credit-card.css';
import IconButton from '@material-ui/core/IconButton';
import Delete from 'mdi-material-ui/Delete';
import Check from 'mdi-material-ui/Check';
import Tooltip from '@material-ui/core/Tooltip';
import Backend from '../Backend';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

function CreditCard(props) {
  const [loading, setLoading] = useState(false);

  const setDefault = async () => {
    setLoading(true);
    try {
      let response = await Backend.business_MakePrimaryCard(props.card.id);
      let data = await response.json();
      if (data.success) props.getStripeAccount();
      setLoading(false);
    } catch (err) {
      props.onDisplayError(err.message || 'An unexpected error has occurred.', err);
      setLoading(false);
    }
  }

  const deleteCard = async () => {
    setLoading(true);
    try {
      let response = await Backend.business_RemoveCard(props.card.id);
      let data = await response.json();
      if (data.success) props.getStripeAccount();
      setLoading(false);
    } catch (err) {
      props.onDisplayError(err.message || 'An unexpected error has occurred.', err);
      setLoading(false);
    }
  }

  return (
    <div className='credit-card' style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 10, top: 15 }}>
        {loading && <CircularProgress size={20} style={{ color: 'white', position: 'relative', top: 5 }} />}
        {!props.isDefault && (
          <Tooltip title='Set as Default Card'>
            <IconButton style={{ color: 'white' }} onClick={setDefault}>
              <Check />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title='Delete Credit Card'>
          <IconButton style={{ color: 'white' }} onClick={deleteCard}>
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
      <div className='credit-card__logo'>
        {props.isDefault ? `${props.card.brand} (default)` : props.card.brand}
      </div>
      <div className='credit-card__number'>xxxx{props.card.last4}</div>
      <div className='credit-card__info'>
        <div className='credit-card__info_name'>
          <div className='credit-card__info_label'>CARDHOLDER'S NAME</div>
          <div>{props.card.name ?? 'Unknown'}</div>
        </div>
        <div className='credit-card__info_expiry'>
          <div className='credit-card__info_label'>VALID</div>
          <div>{`${props.card.exp_month}/${props.card.exp_year}`}</div>
        </div>
      </div>
    </div>
  );
}

export default withAlertAndProgressDialog(CreditCard);