// const { Map: MapContainer, TileLayer, Marker, Popup } = ReactLeaflet;
import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
// import "./Map.css"
export default class SimpleExample extends React.Component {
    constructor() {
        super();
        this.state = {
            lat: 51.505,
            lng: -0.09,
            zoom: 13,
            showPopup: false
        };
    }

    handleClick() {
        this.setState((state) => ({
            showPopup: !state.showPopup
        }));
    }

    render() {
        const position = [this.state.lat, this.state.lng];
        return (
            <>
                {/* <button onClick={() => this.handleClick()}>Toggle popups</button>{" "} */}
                {/* (showPopup: {this.state.showPopup ? "true" : "false"}) */}
                {/* <Map center={position} zoom={this.state.zoom}> */}
                {/* <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                /> */}
                {/* <Marker position={position}>
                        {this.state.showPopup && (
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        )}
                    </Marker> */}
                {/* </Map> */}
            </>
        );
    }
}
