import React, { useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Divider } from '@material-ui/core';
import Backend from '../Backend';
import Password from 'mdi-material-ui/Lock'
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog.js"
import LinearProgress from '@material-ui/core/LinearProgress';

function ChangePasswordDialog(props) {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        currentPass: "",
        newPass: "",
        retypeNewPass: ""
    });


    const inputChanged = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
         await attemptUpdatePassword()
    }


    async function attemptUpdatePassword() {
        setLoading(true)
        try {
            let response = await Backend.user_ResetPass(state.currentPass, state.newPass, state.retypeNewPass)
            let json = await response.json()
            if (!json.success) throw json
            setLoading(false)
            const {
                message,
            } = json
            props.onDisplayAlert(message, "Success")

        } catch (exception) {
            setLoading(false)
            props.onDisplayError(exception.message || 'An unexpected error has occurred.', exception)
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth='sm'
            fullWidth
        >
            <form onSubmit={handleSubmit} >
                <DialogTitle>
                    {loading && <LinearProgress />}
                    <span style={{ position: "relative", top: "7.1px" }} ><Password /> </span>
                    &nbsp;&nbsp;&nbsp;Change Password</DialogTitle>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                    <Divider />
                </div>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label='Current Password'
                                name='currentPass'
                                value={state.currentPass ?? ''}
                                onChange={inputChanged}
                                fullWidth
                                required
                                type='password'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='New Password'
                                name='newPass'
                                value={state.newPass ?? ''}
                                onChange={inputChanged}
                                fullWidth
                                required
                                type='password'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Re-Type New Password'
                                name='retypeNewPass'
                                value={state.retypeNewPass ?? ''}
                                onChange={inputChanged}
                                fullWidth
                                required
                                type='password'
                            />

                        </Grid>



                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={props.onClose}>Close</Button>
                    <Button type="submit" color='primary'>Submit{loading && <React.Fragment>&nbsp;<CircularProgress size={20} /></React.Fragment>}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default withAlertAndProgressDialog(ChangePasswordDialog);