import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Receipt from 'mdi-material-ui/Receipt';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';
import Backend from '../Backend';
import LinearProgress from '@material-ui/core/LinearProgress';

function ConfirmationDialog(props) {
  const [stripeAccount, setStripeAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const getStripeAccount = async () => {
    setLoading(true);
    try {
      let response = await Backend.business_GetStripeAccount();
      let data = await response.json();
      setStripeAccount(data.customer);
      setLoading(false);
    } catch (err) {
      props.onDisplayError(err.message || 'An error has occurred.', err);
      setLoading(false);
    }
  }
  const paymentMethodChanged = e => {
    setPaymentMethod(stripeAccount.sources.data.find(source => source.id === e.target.value));
  }

  const mapPaymentMethods = source => {
    // console.log(source, "map payment method")
    return <FormControlLabel key={source.id} value={source.id} control={<Radio color='primary' />} label={`${source.card.brand} xxxx${source.card.last4}`} />
  }
  const confirmPurchase = () => {
    props.confirmPurchase({ paymentMethod, quantity: props.package.quantity, customer: stripeAccount.id });
  }

  useEffect(() => {
    getStripeAccount();
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth='md'
      fullWidth
    >
      {loading && <LinearProgress />}
      <DialogTitle><Receipt />&nbsp;Confirmation</DialogTitle>
      <DialogContent>
        <Typography variant='h6'>Payment Method</Typography>
        {/* {console.log(paymentMethod)} */}
        <Divider className='divider-margin' />
        {stripeAccount && stripeAccount.sources.data.length === 0 && <Typography>No payment methods on file. Purchase cannot be completed. Please go to the payment method tab to add a payment method.</Typography>}
        {stripeAccount && stripeAccount.sources.data.length > 0 && (
          <FormControl component="fieldset">
            <RadioGroup
              value={paymentMethod?.id ?? ''}
              onChange={paymentMethodChanged}
              row
            >
              {stripeAccount.sources.data.map(mapPaymentMethods)}
            </RadioGroup>
          </FormControl>
        )}
        <br />

        {stripeAccount?.sources.data.length > 0 && paymentMethod == null && <Typography style={{ color: "red", marginTop: "4px", marginBottom: "0" }}>Please select payment method</Typography>}
        <br />
        <Typography variant='h6'>Summary</Typography>
        <Divider className='divider-margin' />
        <Typography>Package: {props.package?.quantity} Job Posts</Typography>
        <Typography>Cost: ${props.package?.cost.toFixed(2)}</Typography>
        <Typography>Payment Method: {paymentMethod ? `${paymentMethod.card.brand} xxxx${paymentMethod.card.last4}` : 'None Selected'}</Typography>
      </DialogContent>
      <DialogActions>
        {console.log(!stripeAccount || stripeAccount.sources.data.length === 0, "btn")}
        <Button color='secondary' onClick={props.onClose}>Cancel</Button>
        <Button color='primary' disabled={stripeAccount?.sources.data.length === 0 || paymentMethod == null} onClick={confirmPurchase}>Purchase</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withAlertAndProgressDialog(ConfirmationDialog);