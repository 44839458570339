import React, { useState } from 'react';
import { Container, Paper, Typography, Divider, Card, CardContent, CardActions, Button, Grid, LinearProgress } from '@material-ui/core';
import Post from 'mdi-material-ui/Post';
import ConfirmationDialog from './ConfirmationDialog';
import Backend from '../Backend';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';
import { useSelector, useDispatch } from 'react-redux';
import { setBusiness } from '../../redux/actions';
import { Link } from 'react-router-dom';

function BuyPosts(props) {
  const business = useSelector(state => state.business);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    showConfirmation: false,
    selectedPackage: null
  })

  const closeConfirmation = () => {
    setState({ ...state, showConfirmation: false, selectedPackage: null });
  }

  const openConfirmation = selectedPackage => e => {
    setState({ ...state, showConfirmation: true, selectedPackage });
  }

  const confirmPurchase = async data => {
    setLoading(true);
    try {
      let response = await Backend.business_PurchaseJobPosts(data);
      if (!response.success) throw response;
      let updatedBusiness = { ...business, posts_remaining: business.posts_remaining + data.quantity };
      dispatch(setBusiness(updatedBusiness));
      props.history.push('/posted_jobs');
      setLoading(false);
    } catch (err) {
      props.onDisplayError(err.message || 'An error has occurred.', err);
      setLoading(false);
    }
  }

  return (
    <Container>
      <Paper style={{ padding: 10 }}>
        {loading && <LinearProgress />}
        <div style={{ display: "flex", justifyContent: "space-between" }} >
          <Typography variant='h6'>Buy Job Posts</Typography>
          <Typography style={{ paddingTop: "5px" }}>
            <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSed-fzExhuU73tt5_pk9gZQu01nmrbskF-MmXSxVG9HOY6OAA/viewform"  >
              Want to become a HireRoad Premium Partner (Recruiter)?
            </a>
          </Typography>
        </div>
        <Divider className='divider-margin' />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  <Post />&nbsp;1 Job Posts
                </Typography>
                <Typography style={{ marginBottom: 12 }} color="primary">
                  Cost: $5
                </Typography>
                <Typography variant="body2" color='textSecondary' component="p">
                  We will load 1 job posts into your account.
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant='outlined' size="small" onClick={openConfirmation({ quantity: 1, cost: 5 })}>Purchase</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  <Post />&nbsp;10 Job Posts
                </Typography>
                <Typography style={{ marginBottom: 12 }} color="primary">
                  Cost: $29 / $2.90 per post
                </Typography>
                <Typography variant="body2" color='textSecondary' component="p">
                  We will load 10 job posts into your account.
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant='outlined' size="small" onClick={openConfirmation({ quantity: 10, cost: 29 })}>Purchase</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  <Post /><Post />&nbsp;50 Job Posts
                </Typography>
                <Typography style={{ marginBottom: 12 }} color="primary">
                  Cost:  $123 / $2.46 per post
                </Typography>
                <Typography variant="body2" color='textSecondary' component="p">
                  We will load 50 job posts into your account.
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant='outlined' size="small" onClick={openConfirmation({ quantity: 50, cost: 123 })}>Purchase</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  <Post /><Post /><Post />&nbsp;100 Job Posts
                </Typography>
                <Typography style={{ marginBottom: 12 }} color="primary">
                  Cost: $225 / $2.25 per post
                </Typography>
                <Typography variant="body2" color='textSecondary' component="p">
                  We will load 100 job posts into your account.
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant='outlined' size="small" onClick={openConfirmation({ quantity: 100, cost: 225 })}>Purchase</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {/* <Divider className='divider-margin' /> */}
        {/* <Typography style={{ margin: " 20px 15px" }}>
          <Link to="" >
            Want to become a HireRoad
            Premium Recruiter?
  </Link>
        </Typography> */}
      </Paper>
      { state.showConfirmation && <ConfirmationDialog open={state.showConfirmation} onClose={closeConfirmation} package={state.selectedPackage} confirmPurchase={confirmPurchase} />}
    </Container >
  )
}

export default withAlertAndProgressDialog(BuyPosts);