import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Backend from '../Backend'
// import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';
import Grid from '@material-ui/core/Grid';
import { LinearProgress } from '@material-ui/core';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import GiftPostDailogue from './GiftPostDailogue';
import DeleteRecruiterDailogue from './DeleteRecruiter'
import StarIcon from '@material-ui/icons/Star';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
// import StarIcon from '@material-ui/icons/Star';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import SearchIcon from '@material-ui/icons/Search';
import crown from "../../images/crown.svg"
import darkCrown from "../../images/crownDark.svg"
import "./listBusiness.css"

function createData(companyName, name, email, phoneNo, remainingPosts, address, id, premium) {
    return { companyName, name, email, phoneNo, remainingPosts, address, id, premium };
}



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'companyName', numeric: false, disablePadding: false, label: 'Company Name' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email Address' },
    { id: 'phoneNo', numeric: true, disablePadding: false, label: 'Phone No.' },
    { id: 'remainingPosts', numeric: true, disablePadding: true, label: 'Remaining Posts' },
    { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.id == "actions" || headCell.id == "remainingPosts" ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id == "actions" || headCell.id == "phoneNo" ? headCell.label : <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null} </TableSortLabel>}


                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, query, setQuery, handleChangeSearch } = props;



    return (
        <Toolbar
            className={clsx(classes.root, {
                // [classes.highlight]: numSelected > 0,
            })}
        >
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Recruiter/Business
                    </Typography>
            <Grid sm={3}>

                <TextField
                    label="Search"
                    value={query}
                    onChange={handleChangeSearch}
                    fullWidth
                />
            </Grid>
        </Toolbar>
    );
};

// EnhancedTableToolbar.propTypes = {
//     numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function ListBusiness(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('companyName');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [loading, setLoading] = useState(false)
    const [businesses, setBusinesses] = useState([])
    const [filteredBusiness, setFilteredBusiness] = useState([])
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // console.log(props, "props")
    const [query, setQuery] = useState('')
    const [rows, setRows] = useState([])
    const [giftCount, setGiftCount] = useState(null)
    const [open, setOpen] = React.useState(false);
    const [openDelModal, setOpenDelModal] = React.useState(false)
    const [emailCopied, setEmailCopied] = useState({ copied: false, index: undefined })
    const [addressCopied, setAddressCopied] = useState({ copied: false, index: undefined })
    const [activeRow, setActiveRow] = React.useState({})

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    // SEARCH COMPONENT 
    const handleChangeSearch = e => {
        const searchInput = e.target.value
        setQuery(e.target.value)
        let filteredData = businesses.filter(value => {
            const userName = value.manager_fname + ' ' + value.manager_lname
            const businessAddress = `${value.name + " " + value.address2 + " " + value.address + " " + value.city + " " + value.state + " " + value.zip}`
            return (
                value.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                userName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.user_docs[0].email.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.phone.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.posts_remaining.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
                businessAddress.toLowerCase().includes(e.target.value.toLowerCase())
            )
        })
        setFilteredBusiness(filteredData);
    }
    useEffect(() => {
        let businessAddress = ''
        if (query) {
            setPage(0);
            setRows(filteredBusiness?.map(val => {
                businessAddress = `${val.name + " " + val.address2 + " " + val.address + " " + val.city + " " + val.state + " " + val.zip}`
                return createData(val.name, val.manager_fname + ' ' + val.manager_lname, val.user_docs[0].email, val.phone, val.posts_remaining, businessAddress, val._id, val?.premium)
            }
            ))
        } else {
            setRows(businesses.map(val => {
                if (val?.user_docs[0]?.admin == true) {
                    return false
                }
                // { console.log(val.manager_fname === "", "name") }
                businessAddress = `${val.name + " " + val.address2 + " " + val.address + " " + val.city + " " + val.state + " " + val.zip}`
                return createData(val.name, val.manager_fname + ' ' + val.manager_lname, val.user_docs[0].email, val.phone, val.posts_remaining, businessAddress, val._id, val.premium)
            }
            ))
        }
    }, [businesses, query])
    // SEARCH COMPONENT ENDS 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handleChangeDense = (event) => {
    //     setDense(event.target.checked);
    // };

    // const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    useEffect(() => {
        handleGetBusinesses()
    }, [])
    const handleGetBusinesses = async () => {
        try {
            setLoading(true)
            let response = await Backend.listBusiness()
            let res = await response.json()
            if (!res.success) throw res
            const businessRes = res?.business?.filter(val => {
                if (val?.user_docs[0]?.admin == false) {
                    return val
                }
            })
            setQuery("")
            setBusinesses(businessRes)
            setLoading(false)

        } catch (e) {
            setLoading(false)
            props.onDisplayError(e.error || e.message || "An unexpected error has occured", e)
        }
    }
    const handleDeleteUser = async (index, id) => {
        try {
            setLoading(true)
            let array = [...businesses]
            let response = await Backend.DeleteBusiness(id)
            let res = await response.json()
            if (!res.success) throw res
            // console.log(index)
            array.splice(index, 1)
            setBusinesses(array)
            // setBusinesses(spliced)
            setLoading(false)
            setOpenDelModal(false)
            props.onDisplayAlert(res.message, "Success")

        } catch (e) {
            setLoading(false)
            setOpenDelModal(false)
            props.onDisplayError(e.error || e.message || "An unexpected error has occured", e)
        }
    }

    const handleGiftPostDailogue = row => {
        setActiveRow(row)
        setOpen(true);
    };
    const handleCloseDailogue = () => {
        setOpen(false);
        setActiveRow({})
        setGiftCount(null)
    };
    const handleOpenDelModal = () => {
        setOpenDelModal(true)
    }
    const handleCloseDelModal = () => {
        setOpenDelModal(false)
    }
    const handleGiftJobPosts = async (id) => {
        try {
            setLoading(true)
            const payload = {
                giftCount,
                id
            }
            let response = await Backend.giftJobPosts(payload)
            let res = await response.json()
            if (!res.success) throw res
            // setBusinesses(res.business)
            setOpen(false)
            setLoading(false)
            setActiveRow({})
            setGiftCount(null)
            props.onDisplayAlert(res.message, "Success")
            handleGetBusinesses()
        } catch (e) {
            setLoading(false)
            setOpen(false)
            setActiveRow({})
            setGiftCount(null)
            props.onDisplayError(e.error || e.message || "An unexpected error has occured", e)
        }
    }
    const inputChanged = (e) => {
        setGiftCount(e.target.value.replace(/[^0-9]/g, ""));
    }
    const handleMarkPremium = async (id) => {
        try {
            setLoading(true)
            let response = await Backend.premiumBadge(id)
            let res = await response.json()
            if (!res.success) throw res
            setLoading(false)
            props.onDisplayAlert(res.message, "Success")
            handleGetBusinesses()
        } catch (e) {
            setLoading(false)
            props.onDisplayError(e.error || e.message || "An unexpected error has occured", e)
        }
    }

    const AddressEllipsis = {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '200px',
        maxWidth: '200px'
    }
    const otherFields = {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '150px'
    }
    const numberFields = {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100px'
    }
    const cursorCopy = {
        cursor: 'copy',
    }
    const handleEmailCopied = (email, index) => {
        // set
        navigator.clipboard.writeText(email);
        setEmailCopied({ copied: true, index: index })
        setTimeout(function () {
            setEmailCopied({ copied: false, index: index })
        }, 1000)
    }
    const handleAddressCopied = (address, index) => {
        navigator.clipboard.writeText(address);
        setAddressCopied({ copied: true, index: index })
        setTimeout(function () {
            setAddressCopied({ copied: false, index: index })
        }, 1000)

        // if (addressCopied) {
        //     setEmailCopied({ copied: false, index: null })
        // }
    }
    const handleClose = () => {

    }
    return (
        <div className={classes.root} style={{ padding: "0 35px" }}>
            <Paper className={classes.paper}>
                {loading && <LinearProgress />}
                <EnhancedTableToolbar query={query} setQuery={setQuery} handleChangeSearch={handleChangeSearch} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                        data={businesses}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            // numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    // const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <>
                                            {row !== false ? <TableRow
                                                hover
                                                // onClick={(event) => handleClick(event, row.name)}
                                                role="checkbox"
                                                // aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                            // selected={isItemSelected}
                                            >
                                                {/* <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell> */}
                                                {/* {console.log(row.name == " " && row.name, "name")} */}
                                                <TableCell component="th" id={labelId} scope="row" className="other-fields" >
                                                    {row.companyName !== " " ? row.companyName : <span>-</span>}
                                                </TableCell>
                                                <TableCell className="other-fields" align="left" >{row.name !== " " ? row.name : <span>-</span>}</TableCell>
                                                <Tooltip title={emailCopied.copied && index == emailCopied.index ? 'Copied!' : 'Copy to Clipboad!'} onClose={handleClose}>
                                                    <TableCell className="address-elipse" style={{ width: "100px" }} align="left" onClick={() => handleEmailCopied(row.email, index)} >
                                                        {row.email !== " " ? row.email : <span>-</span>}
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell align="left" >{row.phoneNo !== " " ? row.phoneNo : <span>-</span>}</TableCell>
                                                <TableCell align="center" style={{ paddingRight: "-80px" }} > {row.remainingPosts !== " " ? row.remainingPosts : <span>-</span>} </TableCell>
                                                <Tooltip title={addressCopied.copied && index == addressCopied.index ? 'Copied!' : 'Copy to Clipboad!'} onClose={handleClose} >
                                                    <TableCell className="address-elipse" align="left" onClick={() => handleAddressCopied(row.address, index)}>
                                                        {row.address !== " " ? row.address : <span>-</span>}
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell align="center" className="address-elipse" >

                                                    <Tooltip title="Delete" >
                                                        <IconButton aria-label="delete" color="secondary"
                                                            onClick={handleOpenDelModal}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                    {/* <IconButton aria-label="view" color="primary">
                                                <VisibilityIcon />
                                            </IconButton> */}
                                                    {/* {console.log(row, "row")} */}
                                                    <Tooltip title="Gift Job Posts">
                                                        <IconButton aria-label="view" style={{ color: "orange" }} onClick={() => handleGiftPostDailogue(row)}>
                                                            <CardGiftcardIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Mark as Premium">
                                                        <IconButton aria-label="view" style={{ color: "orange" }} onClick={() => handleMarkPremium(row.id)}>
                                                            {row.premium ? <img src={crown} style={{ height: "24px" }} /> : <img src={darkCrown} style={{ height: "24px" }} />}
                                                            {/* {row.premium ? <StarIcon /> : <StarBorderOutlinedIcon />} */}
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <DeleteRecruiterDailogue open={openDelModal} handleCloseDailogue={handleCloseDelModal} handleDeleteUser={handleDeleteUser} id={row.id} index={index} />
                                                <GiftPostDailogue open={open} handleCloseDailogue={handleCloseDailogue} business={activeRow} handleGiftJobPosts={handleGiftJobPosts} inputChanged={inputChanged} giftCount={giftCount} key={index} />
                                            </TableRow> : null}

                                        </>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{ height: (dense ? 33 : 53) * emptyRows }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </div >
    );
}
export default withAlertAndProgressDialog(ListBusiness);