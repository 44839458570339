
 const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 //const addressRE = /^(\d+) ?([A-Za-z](?= ))? (.*?) ([^ ]+?) ?((?<= )APT)? ?((?<= )\d*)?$/;
 const cityRE = /^[a-zA-Z]{2,20}\s?[a-zA-Z]{0,20}\s?[a-zA-Z]{0,20}$/;
 const zipRE = /^\d{5}$/;
 const phoneRE = /^\d{10}$/
 const passwordRE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,20}$/; 

export let validateEmail = (email) => {
    return emailRE.test(email)
}

export let validateAddr = (addr) => {
    //return addressRE.test(addr)
    return true;
}

export let validateCity = (city) => {
    return cityRE.test(city)
}

export let validateZip = (zip) => {
    return zipRE.test(zip)
}

export let validatePhone = (phone) => {
    return phoneRE.test(phone)
}

export let validatePassword =  (pass) => {
    return  passwordRE.test(pass)
}