//export const API_URL = 'https://temporal-sweep-229417.appspot.com'; // gcloud flex
export const API_URL = 'https://covershiftz-free-server.herokuapp.com'; // heroku free dyno
// export const API_URL = 'http://localhost:4000'; // heroku free dyno
// export const API_URL = 'https://hireroad-server.herokuapp.com'
// const updateEndTime = require('../utils/functions')


let getToken = () => localStorage.getItem('token')?.replace(/"/g, '');
let headerFormData = {};

export default class Backend {
    /*
    This function returns the auth header, or you can set a custom one by passing in the token as a parameter
    */
    static getHeader(token) {
        if (token) {
            const _header = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
            return _header;
        } else {
            return { 'Accept': 'application/json', 'Content-Type': 'application/json' };
        }
    }

    /* 
    This function is called when a user attempts to login
    Returns the user object without hash if any was sucessfully found, and the JSON web token
    */

    static SuggestPlace(query) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({
            //     'email': email,
            //     'password': password,
            // }),
            timeout: 8000,
        };

        return fetch(`https://photon.komoot.io/api/?q=${query}&limit=5&lang=en`,)
            .then((response) => response.json())
            .then(async (res) => {
                // login successful if there's a jwt token in the response
                // if (user.token) {
                //     headerFormData = { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + user.token };
                //     // store user details and jwt token in local storage to keep user logged in between page refreshes
                //     localStorage.setItem('user', JSON.stringify(user));
                //     localStorage.setItem('token', JSON.stringify(user.token));

                // }
                return res;
            });
    }

    static userAuth(email, password) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'email': email,
                'password': password,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/users/authenticate_business`, requestOptions)
            .then((response) => response.json())
            .then(async (user) => {
                // login successful if there's a jwt token in the response
                if (user.token) {
                    headerFormData = { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + user.token };
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', JSON.stringify(user.token));

                }
                return user;
            });
    }

    static adminUserAuth(email, password) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'email': email,
                'password': password,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/users/authenticate_admin`, requestOptions)
            .then((response) => response.json())
            .then(async (user) => {
                // login successful if there's a jwt token in the response
                if (user.token) {
                    headerFormData = { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + user.token };
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', JSON.stringify(user.token));

                }
                return user;
            });
    }

    //USE ONLY FOR FB LOGIN
    static userAuth_fb(email) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'email': email,
                'password': "",
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/users/authenticate_fb`, requestOptions)
            .then((response) => response.json())
            .then(async (user) => {
                // login successful if there's a jwt token in the response
                if (user.token) {
                    // bearer_tokFen = user.token;
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                }
                return user;
            });
    }

    static user_ResetPass(old, newP, reNewP) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'old': old,
                'new': newP,
                'reNewP': reNewP
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/users/reset`, requestOptions);
    }

    // registering a NEW account as a worker
    static registerWorkerAccount(registrationParams) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'type': 'worker',
                'fname': registrationParams.fname,
                'lname': registrationParams.lname,
                'birthdate': registrationParams.birthDate,
                'phone': registrationParams.phone,
                'address': registrationParams.address,
                'address2': registrationParams.address2,
                'city': registrationParams.city,
                'state': registrationParams.state,
                'zip': registrationParams.zip,
                'email': registrationParams.email,
                'password': registrationParams.password,
                'last4': registrationParams.last4,
            }),
            timeout: 15000,
        };

        return fetch(`${API_URL}/users/register`, requestOptions);
    }

    // registering a NEW account as a business
    static registerBusinessAccount(registrationParams, regType) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'type': regType,
                'name': registrationParams.businessName,
                'description': registrationParams.description,
                'fname': registrationParams.fname,
                'lname': registrationParams.lname,
                'birthdate': registrationParams.birthDate,
                'phone': registrationParams.phone,
                'address': registrationParams.address,
                'address2': registrationParams.address2,
                'city': registrationParams.city,
                'state': registrationParams.state,
                'zip': registrationParams.zip,
                'email': registrationParams.email,
                'password': registrationParams.password,
            }),
            timeout: 15000,
        };

        return fetch(`${API_URL}/users/register`, requestOptions);
    }

    /* 
    Fetches the associated worker account obj from backend, then returns the worker
    */
    static getWorker(worker_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/workers/${worker_id}`, requestOptions)
            .then((response) => response.json())
            .then(async (worker) => {
                return worker;
            });
    }

    /* 
    Fetches the associated buisness account obj from backend, then returns the business
    */
    static getBusiness(business_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(getToken()),
            timeout: 4000,
        };
        return fetch(`${API_URL}/businesses/${business_id}`, requestOptions)
            .then((response) => response.json())
            .then(async (business) => {
                return business;
            });
    }

    static getBusinessJobs() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };
        return fetch(`${API_URL}/businesses/jobs/current`, requestOptions);
    }

    static worker_SaveProfile(worker) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'worker': worker
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/workers/save`, requestOptions);
    }

    // Returns a PREVIEW of all jobs within the given radius
    static getJobPreviewsWithinRadius(longitude, latitude, radius) {

        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'lon': longitude,
                'lat': latitude,
                'rad': radius,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/jobposts/worker/within`, requestOptions);
    }

    static appliedToJob(jobpost_id, BGCheck) {

        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'jobpost_id': jobpost_id,
                'BGCheck': BGCheck,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/jobposts/worker/apply`, requestOptions);
    }

    // Returns jobpost PREVIEWS
    static workerRequestBusinessJobposts(business_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(getToken()),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/business/${business_id}`, requestOptions);
    }

    // STRIPE
    static worker_GetStripeAccount() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/workers/stripe/account`, requestOptions);
    }

    /*static worker_SaveStripeAccount(fields_needed) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'fields_needed': fields_needed
            }),
            timeout: 4000,
        };
        return fetch(`${API_URL}/workers/stripe/account/save`, requestOptions);
    }*/

    static worker_LinkBank(token) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'stripe_token': token,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/workers/stripe/account/bank/link`, requestOptions);
    }

    static worker_RemoveBank(bank_account_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'bank_account_id': bank_account_id,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/workers/stripe/account/bank/remove`, requestOptions);
    }

    static worker_MakePrimaryBank(bank_account_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'bank_account_id': bank_account_id,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/workers/stripe/account/bank/primary`, requestOptions);
    }

    static worker_GetBalance() {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            timeout: 8000,
        };
        return fetch(`${API_URL}/workers/stripe/account/balance`, requestOptions);
    }

    static worker_InstantPayout() {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            timeout: 8000,
        };
        return fetch(`${API_URL}/workers/stripe/account/instantpayout`, requestOptions);
    }

    static worker_UploadAvatar(avatarFormData) {
        const requestOptions = {
            method: 'POST',
            headers: headerFormData,
            body: avatarFormData,
            timeout: 8000,
        };

        return fetch(`${API_URL}/workers/avatar`, requestOptions);
    }

    static getReview(review_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
        };

        return fetch(`${API_URL}/reviews/${review_id}`, requestOptions);
    }

    static worker_getBusinessReviewPreviews(business_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
        };

        return fetch(`${API_URL}/reviews/worker/businessprofile/${business_id}`, requestOptions);
    }

    static worker_GetReviewMadeBySelf(jobpost_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
        };

        return fetch(`${API_URL}/reviews/worker/reviews/${jobpost_id}`, requestOptions);
    }

    static worker_GetReviewsMadeBySelf() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
        };

        return fetch(`${API_URL}/reviews/worker/reviews`, requestOptions);
    }

    static business_GetReviewMadeBySelf(jobpost_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/reviews/business/reviews/${jobpost_id}`, requestOptions);
    }

    static getBusinessReviews(business_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/reviews/business/reviews`, requestOptions);
    }

    static business_GetReviewsMadeByOthers(worker_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader()
        };

        return fetch(`${API_URL}/reviews/business/workerProfile/${worker_id}`, requestOptions);
    }

    static isAuthenticated(token) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(getToken()),
            timeout: 3000
        };

        return fetch(`${API_URL}/users/authcheck`, requestOptions)
            .then((response) => response.json())
            .then(async (result) => {
                // login successful if there's a jwt token in the response
                if (result.message === "Valid Token") {
                    headerFormData = { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + token };
                }
                return result;
            });
    }

    static saveNotificationToken(token) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'token': token,
            }),
            timeout: 4000,
        };
        return fetch(`${API_URL}/users/push_token`, requestOptions);
    }

    static removeNotificationToken(token) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'token': token,
            }),
            timeout: 4000,
        };
        return fetch(`${API_URL}/users/push_token/remove`, requestOptions);
    }

    static business_SaveProfile(business) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'business': business
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/businesses/save`, requestOptions);
    }

    static createJobpost(jobpostParams) {
        // const updateEndTime = (dateString) => {
        //     var date = new Date(dateString);
        //     date.setHours(23, 59, 59);   // Set hours, minutes and seconds
        //     return new Date(date.toString());
        // }
        // let endTime = updateEndTime(jobpostParams.end_time)
        // console.log(jobpostParams.end_time, "component", endTime.toISOString())
        // const endTime = updateEndTime(jobpostParams.end_time)
        const endTime = jobpostParams.end_time
        const formatEndTime = `${endTime.getFullYear()}-${('0' + endTime.getMonth())}-${('0' + endTime.getDate()).slice(-2)}T23:59:59`
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'title': jobpostParams.title,
                'description': jobpostParams.description,
                'start_time': jobpostParams.start_time,
                'end_time': jobpostParams.end_time,
                'hourly_rate': jobpostParams.hourly_rate,
                'location': jobpostParams.location,
                'geometry': jobpostParams.geometry,
                'category': jobpostParams.category,
                'job_type': jobpostParams.job_type,
                'external_application_href': jobpostParams.external_application_href,
                'benefits': jobpostParams.benefits,
                'remote': jobpostParams.remote,
                'annual_salary': jobpostParams.annual_salary
            }),
            timeout: 10000,
        };

        return fetch(`${API_URL}/jobposts/business/create`, requestOptions);
    }

    static business_UploadAvatar(avatarFormData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${getToken()}` },
            body: avatarFormData,
            timeout: 8000,
        };

        return fetch(`${API_URL}/businesses/avatar`, requestOptions);
    }

    static worker_GetJobpost(jobpost_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/worker/${jobpost_id}`, requestOptions);
    }

    static business_GetJobpost(jobpost_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/${jobpost_id}`, requestOptions);
    }

    static business_GetAllJobPosts(business_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(getToken()),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/business/${business_id}/all?excludeShifts=true`, requestOptions)
            .then(response => response.json())
            .then(jobPosts => jobPosts)
            .catch(error => error)
    }

    static business_CancelJobpost(jobpost_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'jobpost_id': jobpost_id,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/business/cancel`, requestOptions);
    }

    static getWorkersOfInterest(jobpost_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'jobpost_id': jobpost_id
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/business/woi`, requestOptions);
    }

    static getJobsPending() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/workers/jobs/pending`, requestOptions)
            .then((response) => response.json())
            .then(async (jobposts) => {
                return jobposts;
            })
    }

    static getJobsCurrent() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(getToken()),
            timeout: 4000,
        };

        return fetch(`${API_URL}/workers/jobs/current`, requestOptions)
            .then((response) => response.json())
            .then(async (jobposts) => {
                return jobposts;
            })
    }

    static getJobsPast() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(getToken()),
            timeout: 4000,
        };

        return fetch(`${API_URL}/workers/jobs/past`, requestOptions)
            .then((response) => response.json())
            .then(async (jobposts) => {
                return jobposts;
            })
    }

    // Business Stripe
    static business_GetStripeAccount() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(getToken()),
            timeout: 8000,
        };

        return fetch(`${API_URL}/businesses/stripe/account`, requestOptions);
    }

    /*static business_SaveStripeAccount(fields_needed) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'fields_needed': fields_needed
            }),
            timeout: 8000,
        };
        return fetch(`${API_URL}/businesses/stripe/account/save`, requestOptions);
    }*/

    static business_LinkCard(stripeToken) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'stripe_token': stripeToken,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/businesses/stripe/account/card/link`, requestOptions);
    }

    static business_RemoveCard(bank_account_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'source_id': bank_account_id,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/businesses/stripe/account/card/remove`, requestOptions);
    }

    static business_PurchaseJobPosts({ paymentMethod, quantity, customer }) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                paymentMethod,
                quantity,
                customer
            })
        }

        return fetch(`${API_URL}/businesses/purchase_job_posts`, requestOptions)
            .then(response => response.json())
            .then(data => data)
            .catch(err => err)
    }

    static business_MakePrimaryCard(bank_account_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'source_id': bank_account_id,
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/businesses/stripe/account/card/primary`, requestOptions);
    }

    static resetPassEmail(email) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                'email': email,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/users/passwordResetEmail`, requestOptions)
            .then(async function (response) {
                if (response.status === 400) {
                    //alert("Account with that email was not found");
                    return 400
                }
                else if (response.status === 200) {
                    //alert("Password Reset");
                    return 200
                }
            })
    }

    static getWorkerNotifications() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 8000,
        };

        return fetch(`${API_URL}/notifications/worker`, requestOptions);
    }

    static getBusinessNotifications() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 8000,
        };

        return fetch(`${API_URL}/notifications/business`, requestOptions);
    }

    static clearNotification(notification_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'notification_id': notification_id,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/notifications/clear`, requestOptions);
    }

    static getBusinessHiringHistory() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/businesses/jobs/past`, requestOptions);
    }

    static getBusinessJobCost(hourly_rate, start_time, end_time) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'hourly_rate': hourly_rate,
                'start_time': start_time,
                'end_time': end_time,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/business/cost`, requestOptions);

    }

    static business_AcceptWorkerForJob(worker_id, jobpost_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'worker_id': worker_id,
                'jobpost_id': jobpost_id,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/business/acceptworker`, requestOptions);
    }

    static business_DeclineWorkerForJob(worker_id, jobpost_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'worker_id': worker_id,
                'jobpost_id': jobpost_id,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/business/declineworker`, requestOptions);
    }

    static worker_ConfirmJobOffer(jobpost_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'jobpost_id': jobpost_id,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/worker/acceptoffer`, requestOptions);
    }

    static worker_DeclineJobOffer(jobpost_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'jobpost_id': jobpost_id,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/worker/declineoffer`, requestOptions);
    }

    static enableOrDisablePushNotifications(enabled) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'enabled': enabled,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/notifications/push/enabled`, requestOptions);
    }

    static addLinkedIn(linkedin_name) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'linkedin': linkedin_name,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/workers/addlinkedin`, requestOptions);
    }

    static worker_CheckIn(jobpost_id, code) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'jobpost_id': jobpost_id,
                'checkin_code': code,
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/worker/checkin`, requestOptions);
    }

    static checkAccess(accessCode) {
        // //this function is only used for early access testing. allows access to the app if the correct code is input 
        const requestOptions = {
            method: "POST",
            body: `{\n\t\"accessCode\": \"${accessCode}\"\n}`,
            headers: {
                "Content-Type": "application/json",
            },
        };

        // console.log(requestOptions)
        // return fetch(`${API_URL}/users/checkearlyaccess`, requestOptions)
        // .then((response) => response.json())
        // .then(async (access) => {
        //     return access;
        // });

        return fetch(`${API_URL}/users/checkearlyaccess`, requestOptions);
    }

    static business_GetJobpostStatus(jobpost_id) {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 4000,
        };

        return fetch(`${API_URL}/jobposts/business/status/${jobpost_id}`, requestOptions);
    }

    static createReview(ReviewParams) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'type': ReviewParams.type,
                'title': ReviewParams.title,
                'message': ReviewParams.message,
                'rating': ReviewParams.rating,
                'active': ReviewParams.active,
                'creation_date': ReviewParams.creation_date,
                'business_id': ReviewParams.business_id,
                'worker_id': ReviewParams.worker_id,
                'jobpost_id': ReviewParams.jobpost_id,
            }),
            timeout: 10000,
        };

        return fetch(`${API_URL}/reviews/create`, requestOptions);
        // .then(async (result) => {
        //     if (result.message == 'Successfully posted review.') {
        //         console.log("Successfully posted review");
        //     }
        //     else {
        //         console.log("review post unsuccessful.");
        //         console.log("RESULT:", result);
        //         return result;
        //     }
        // });
    }

    static worker_UpdateBusinessRating(business_id, rating) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'business_id': business_id,
                'rating': rating
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/workers/update/rating`, requestOptions);
    }

    static business_UpdateWorkerRating(worker_id, rating) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'worker_id': worker_id,
                'rating': rating
            }),
            timeout: 4000,
        };

        return fetch(`${API_URL}/businesses/update/rating`, requestOptions)
    }

    static WorkerGetPayouts() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(),
            timeout: 8000,
        };

        return fetch(`${API_URL}/workers/stripe/account/payouts`, requestOptions);
    }

    static worker_removePending(jobpost_id) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({
                'jobpost_id': jobpost_id
            }),
            timeout: 8000,
        };

        return fetch(`${API_URL}/workers/jobs/pending/remove`, requestOptions);
    }

    static business_checkPaymentOnFile() {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            timeout: 8000,
        };

        return fetch(`${API_URL}/jobposts/business/checkpaymentonfile`, requestOptions);
    }

    static remove_user(userEmail) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'email': userEmail
            }),
            timeout: 8000
        }

        return fetch(`${API_URL}/users/remove_user`, requestOptions);
    }

    static add_admin(userEmail) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'email': userEmail
            }),
            timeout: 8000
        }

        return fetch(`${API_URL}/users/add_admin`, requestOptions)

    }

    static sendMassNotifications(notif) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'title': notif.title,
                'message': notif.message,
                'style': notif.style,
                'type': notif.type,
                'action': notif.action,
                'data': {},

            }),
            timeout: 8000
        }

        if (notif.type === 'business') {
            return fetch(`${API_URL}/notifications/send_notifications_to_businesses`, requestOptions)
        } else if (notif.type === 'worker') {
            return fetch(`${API_URL}/notifications/send_notifications_to_workers`, requestOptions)
        } else {
            return fetch(`${API_URL}/notifications/send_notifications_to_all_users`, requestOptions)
        }


    }
    // ADMIN FUNCTIONS 
    static listBusiness() {
        const requestOptions = {
            method: 'GET',
            headers: this.getHeader(getToken()),
            timeout: 8000
        }

        return fetch(`${API_URL}/businesses`, requestOptions)

    }

    static DeleteBusiness(id) {
        const requestOptions = {
            method: "POST",
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'id': id
            }),
            timeout: 8000
        }
        return fetch(`${API_URL}/users/remove_user`, requestOptions)
    }

    static giftJobPosts({ id, giftCount }) {
        const requestOptions = {
            method: "POST",
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'id': id,
                'giftCount': giftCount
            }),
            timeout: 8000
        }
        return fetch(`${API_URL}/businesses/gift/jobposts`, requestOptions)
    }

    static listApplicants(applicants) {
        const requestOptions = {
            method: 'POST',
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'applicants': applicants
            }),
            timeout: 8000,
        }

        return fetch(`${API_URL}/workers/get_applicants`, requestOptions)

    }
    static premiumBadge(id) {
        const requestOptions = {
            method: "POST",
            headers: this.getHeader(getToken()),
            body: JSON.stringify({
                'id': id,
            }),
            timeout: 8000
        }
        return fetch(`${API_URL}/businesses/businesses/premium`, requestOptions)
    }

}
