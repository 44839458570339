
import React, { } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function GiftPostDailogue({ seeMore, handleCloseModal, description }) {
    return (
        <Dialog
            open={seeMore}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            maxWidth='sm'
            fullWidth
            // BackdropProps={{ style: { opacity: 0.2, outline: "none" } }}
            style={{ outline: "none" }}
        >
            <DialogTitle><DescriptionIcon />&nbsp;&nbsp;&nbsp; <span>Job Description</span> </DialogTitle>
            <DialogContent>
                <Typography variant="p"><pre style={{ fontFamily: 'sans-serif' }}>{description}</pre></Typography>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={handleCloseModal}>Close</Button>
            </DialogActions>
        </Dialog >
    )
}

export default GiftPostDailogue;

// import React from 'react';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

// export default function AlertDialog({ seeMore, handleCloseModal, description }) {
//     //   const [open, setOpen] = React.useState(false);

//     //   const handleClickOpen = () => {
//     //     setOpen(true);
//     //   };

//     //   const handleClose = () => {
//     //     setOpen(false);
//     //   };

//     return (
//         <Dialog
//             open={seeMore}
//             onClose={handleCloseModal}
//             TransitionComponent={Transition}
//             maxWidth='sm'
//             fullWidth
//             BackdropProps={{ style: { opacity: 0.1, outline: "none" } }}
//             style={{ outline: "none" }}
//         >
//             <DialogTitle><DescriptionIcon />&nbsp;&nbsp;&nbsp; <span>Job Description</span> </DialogTitle>
//             <DialogContent>
//                 <Typography variant="p">{description}</Typography>
//             </DialogContent>
//             <DialogActions>
//                 <Button color='secondary' onClick={handleCloseModal}>Close</Button>
//             </DialogActions>
//         </Dialog >
//     );
// }