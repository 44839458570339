// v3 
export default [
  "Accounting",
  "Acting",
  "Admin / Clerical",
  "Advertising",
  "Aerospace",
  "Agriculture / Farming",
  "Agriculture / Live Stock",
  "Air Traffic Control",
  "Aircraft / Cargo",
  "Aircraft / Maintenance",
  "Aircraft / Pilot",
  "Airfield / Ops",
  "Airline / Commercial",
  "Airline / Flight Attendant",
  "Amusement",
  "Analyst",
  "Answering Service / Operator",
  "Architect",
  "Art / Gallery / Museum",
  "Art / Graphic Designer",
  "Art / Illustrator",
  "Art Director",
  "Assembly",
  "Auction",
  "Audio Specialist / Technology",
  "Author / Blogger / Editor",
  "Automotive / Assembly",
  "Automotive / Dealer GM",
  "Automotive / Dealer Marketing",
  "Automotive / Dealer Sales",
  "Automotive / Dealer Training",
  "Automotive / Detailer",
  "Automotive / Finance",
  "Automotive / Fixed Ops",
  "Automotive / Manufacturing",
  "Automotive / Mechanic",
  "Automotive / Parts",
  "Automotive / Prep & Clean",
  "Automotive / Retail",
  "Automotive / Service Department",
  "Automotive / Supplier",
  "Automotive / Tech",
  "Banking / Lending / Finance",
  "Beauty / Cosmotology",
  "Board Member / Director",
  "Boiler Operations",
  "Bookkeeping",
  "Broadcast / Management",
  "Broadcast / Operations",
  "Broadcast / Talent",
  "Business Analyst",
  "Business Management",
  "Cable Install / Repair",
  "Campaign / Political",
  "Casino Occupations",
  "Cellular",
  "Childcare / Daycare",
  "City Appraiser",
  "City Services",
  "Cleaning",
  "CNC Operator",
  "Communications Specialist",
  "Community Service",
  "Computer / Network Architect",
  "Computer / Programmer",
  "Computer / Repair",
  "Computer / Sales",
  "Conservation / Forester",
  "Conservation Science",
  "Construction / Building",
  "Construction / Commercial",
  "Construction / Extraction",
  "Construction / Hazard",
  "Construction / Heavy Equip",
  "Construction / Help",
  "Construction / Manager",
  "Construction / Repair",
  "Construction / Residential",
  "Construction / Structural Engineering",
  "Consulting",
  "Copywriter / Editor",
  "Court Service",
  "Creative / Concept",
  "Creative / Director",
  "Creative / Occupation",
  "Credit Counselor",
  "Crisis Response",
  "Crowd Control",
  "Customer Service",
  "Data Analytics / Big Data",
  "Data Entry",
  "Database Administrator",
  "Daycare / Child Care",
  "Delivery",
  "Dental / Office",
  "Dental / Specialization",
  "Desktop Publishing",
  "Digital Print / Printing Service",
  "Disaster Related",
  "Distribution",
  "Diver",
  "Door To Door / Sales",
  "Dredge Ops",
  "Driver (Local)",
  "Dry Cleaning",
  "Education / Admin",
  "Education / Assistant",
  "Education / Educator K-12",
  "Education / Educator College",
  "Education / Instruction",
  "Education / Librarian",
  "Education / Professor PhD",
  "Education / Substitute & TA",
  "Education / Training / Tutor",
  "Education / Virtual",
  "Elderly / Aging",
  "Electrical / Install",
  "Electrical / Line",
  "Electrical / Repair",
  "Electrical / Security & Alarm",
  "Emergency Management",
  "EMS Transport",
  "Engineering / Civil",
  "Engineering / Design",
  "Engineering / Electrical",
  "Engineering / Environmental",
  "Engineering / General",
  "Engineering / Mechanical",
  "Engineering / Structural",
  "Entertainment / Crew",
  "Entertainment / Performance",
  "Essential / General",
  "Event & Festival",
  "Executive / Corporate",
  "Executive / Grass Root",
  "Executive / Non-Profit",
  "Executive / Services",
  "Faith / Religion / Worship",
  "Farming / Crops",
  "Farming / Livestock",
  "Farming / Processing",
  "Fast Food",
  "Fast Food Delivery",
  "Financial / Advisor",
  "Financial / Examiner",
  "Financial / Invest Analyst",
  "Financial / Risk",
  "Fire Safety",
  "Food / Bar / Banquet",
  "Food Production / Processing",
  "Forensic Science",
  "Fundraising",
  "Funeral Service",
  "Gaming",
  "Gas / Oil Industry",
  "Gas Station / Convenience",
  "General Labor",
  "Government",
  "Grant Writer",
  "Grocery Delivery",
  "Grocery Staff",
  "Grocery Warehouse",
  "Gym",
  "Healthcare / Practitioner",
  "Healthcare / Tech",
  "Heating / Cooling",
  "Heavy Equipment",
  "Hi-Lo",
  "Historian / Historical",
  "Home Service",
  "Hospitality",
  "Hotel & Logging",
  "Human Resources",
  "Human Services",
  "Industrial / Crane",
  "Industrial / Operator",
  "Industrial / Machinery",
  "Industrial / Maintenance",
  "Industrial / Mechanic",
  "Industrial / Millwright",
  "Industrial / Refractor",
  "Insurance / Agent",
  "Insurance / Claim",
  "Insurance / Service",
  "Insurance / Underwriter",
  "Interior Design",
  "Intermodal / Freight",
  "Internships",
  "Interpreter Sign Language",
  "Inventory Control",
  "Investment",
  "IT",
  "Janitorial",
  "JIT / OTR",
  "Journalist / Editor",
  "Journalist / Reporter",
  "Journalist / Writer",
  "Landscaping / Commercial",
  "Landscaping / Residential",
  "Lawyer / Legal",
  "Law Enforcement",
  "Lawn Maintenance",
  "Logistics",
  "Machine Repair",
  "Machining",
  "Maintenance",
  "Management / Comp & Benefit",
  "Management / Executive",
  "Management / General",
  "Management / Operations",
  "Manufacturing",
  "Market Research",
  "Marketing / Promotion",
  "Martial Arts",
  "Material Moving",
  "Mathematical",
  "Media / News",
  "Medical / Billing",
  "Medical / Covid",
  "Medical / Equipment",
  "Medical / Essential",
  "Medical / Health Services",
  "Medical / Hospital",
  "Medical / Nursing",
  "Medical / Office",
  "Medical / Paramedic",
  "Medical / Pharm",
  "Medical / Specialist",
  "Medical / Supplies",
  "Medical / Testing",
  "Medical / Transcription",
  "Military / Armed Forces",
  "Millwright",
  "Mining",
  "Mobility / Transportation",
  "Motivational",
  "Motorsport",
  "Multimedia",
  "Office Administration",
  "Oil Operations",
  "Operator (911)",
  "Optical",
  "Outdoor Market",
  "Packaging",
  "Painting",
  "Pandemic Related",
  "Personal Assistant",
  "Pet Retail",
  "Pet Services & Grooming",
  "Photography",
  "Plant / System Ops",
  "Plastics",
  "Plumbing",
  "Programmer",
  "Promoter",
  "Property Management",
  "Public Relations / Speaking",
  "PublicTransportation",
  "Radio / Production",
  "Radio / Talent",
  "Railway / Railroad",
  "Real Estate",
  "Recreation",
  "Recruiter",
  "Research Assistant",
  "Restaurant",
  "Retail",
  "Roads / Bridges",
  "Roofing",
  "RV / Dealer GM",
  "RV / Dealer Marketing",
  "RV / Finance",
  "RV / Fixed Ops",
  "RV / Mechanic",
  "RV / Sales",
  "Sales / Account Rep",
  "Sales / Door To Door",
  "Sales / International",
  "Sales / Other",
  "Sales / Telephone",
  "Sanitation / Sanitization",
  "School / Maintenance",
  "Science",
  "Secret Shopper",
  "Security",
  "Sewing",
  "Ship / Captain",
  "Ship / Engineers",
  "Ship / Operations",
  "Ship / Sailor",
  "Shipping / Receiving",
  "Signage / Installation",
  "Signage / Manufacturing",
  "Small Business Help",
  "Snow Removal",
  "Social Distancing",
  "Social Media Content",
  "Social Media Management",
  "Social Services",
  "Software Development",
  "Sport Sponsorship",
  "Sports",
  "Steel Industry",
  "Surveyor",
  "Tax / Audit",
  "Tax / Preparation",
  "Technology Innovation",
  "Telecommunications",
  "Telemarketing",
  "Television / General",
  "Television / Studio",
  "Television / Talent",
  "Textiles",
  "Tool & Die",
  "Tourism",
  "Training / Coaching",
  "Translation / Signing",
  "Translation / Spoken",
  "Translation / Written",
  "Transportation",
  "Upholstery",
  "Utility",
  "Veterans (USA) Services",
  "Veterinarian",
  "Video Services",
  "Virtual Assistant",
  "Warehouse",
  "Waste Management",
  "Welding",
  "WFH / Work From Home",
  "Other"
]



// v2 

// export default [
//   "Acting",
//   "Admin / Clerical",
//   "Advertising",
//   "Aerospace",
//   "Agriculture / Farming",
//   "Agriculture / Live Stock",
//   "Airline",
//   "Architecture",
//   "Art Director",
//   "Art / Gallery / Museum",
//   "Amusement",
//   "Analytics",
//   "Assembly",
//   "Auction",
//   "Audio Specialist / Technology",
//   "Author / Blogger / Editor",
//   "Automotive / Dealer GM",
//   "Automotive / Dealer Sales Staff",
//   "Automotive / Dealer Sales Support",
//   "Automotive / Dealer Location Marketing",
//   "Automotive / Detailer",
//   "Automotive / Finance",
//   "Automotive / Fix Ops",
//   "Automotive / Manufacturing",
//   "Automotive / Mechanical",
//   "Automotive / Parts",
//   "Automotive / Prep & Clean",
//   "Automotive / Retail",
//   "Automotive / Service Department",
//   "Automotive / Supplier",
//   "Banking / Lending / Finance",
//   "Beauty / Cosmetology",
//   "Board Member / Director",
//   "Bookkeeping",
//   "Broadcast",
//   "Business Management",
//   "Cable Install / Repair",
//   "Campaign / Political",
//   "City Appraiser",
//   "City Services",
//   "Cleaning",
//   "CNC Operator",
//   "Communications",
//   "Computer / Sales",
//   "Computer / Repair",
//   "Construction / Building",
//   "Construction / commercial",
//   "Construction / Heavy Equip",
//   "Construction / Residential",
//   "Construction / Repair",
//   "Construction / Structural Engineering",
//   "Consulting",
//   "Copywriter / Editor",
//   "Creative Director",
//   "Creative Works",
//   "Crisis Response",
//   "Crowd Control",
//   "Customer Service",
//   "Data Entry",
//   "Data Analytics",
//   "Database Administrator",
//   "Daycare / Child Care",
//   "Delivery",
//   "Dental Office",
//   "Dental (Specialization)",
//   "Door To Door / Sales",
//   "Digital Print / Print Services",
//   "Distribution",
//   "Disaster Related",
//   "Driver (Local)",
//   "Drive Thru Services",
//   "Dry Cleaning",
//   "Education / Assistant",
//   "Education / Online Instructor",
//   "Education / Training / Tutor",
//   "Education / Substitute / Teacher",
//   "Education Teacher / Professor",
//   "Elderly / Aging",
//   "Electric",
//   "Engineering",
//   "Engineering / Design",
//   "Engineering / Mechanical",
//   "Engineering / Structural",
//   "Entertainment",
//   "Electrical",
//   "Essential",
//   "Event Festival",
//   "Executives",
//   "Executive Services",
//   "Faith / Religion / Worship",
//   "Farming",
//   "Fast Food",
//   "Fast Food Delivery",
//   "Finance",
//   "Financial Advisor",
//   "Food / Bar / Banquet",
//   "Food Production",
//   "Fundraising",
//   "Funeral Services",
//   "Gaming",
//   "Gas / Oil Industry",
//   "Gas Station / Convenience",
//   "General Labor",
//   "Government",
//   "Grant Writer",
//   "Graphic Design",
//   "Grocery Staff",
//   "Grocery Warehouse",
//   "Grocery Delivery",
//   "Gym",
//   "Health",
//   "Heavy Equipment",
//   "Hi-Lo",
//   "Home Services",
//   "Hospitality",
//   "Human Resources",
//   "Human Services",
//   "Illustrator",
//   "Insurance Agent",
//   "Insurance Claims",
//   "Insurance Services",
//   "Installation",
//   "Intermodal / Freight",
//   "Internship",
//   "Interior Design",
//   "Interpreter Sign Language",
//   "Inventory Control",
//   "Investment",
//   "IT",
//   "Janitorial",
//   "JIT / OTR",
//   "Journalist / Editor",
//   "Journalist / Writer",
//   "Journalist / Reporter",
//   "Landscaping",
//   "Law Enforcement",
//   "Legal Services",
//   "Logistics",
//   "Machine Repair",
//   "Machining",
//   "Maintenance",
//   "Mathematical",
//   "Management",
//   "Manufacturing",
//   "Marketing / Promotion",
//   "Market Research",
//   "Martial Arts",
//   "Media / News",
//   "Medical Billing",
//   "Medical / Covid",
//   "Medical / Essential",
//   "Medical Equipment",
//   "Medical Hospital",
//   "Medical Nursing",
//   "Medical Office",
//   "Medical Paramedic",
//   "Medical Specialist",
//   "Medical Supplies",
//   "Medical Testing",
//   "Medical Transcription",
//   "Military / Armed Forces",
//   "Millwright / Welding",
//   "Mining",
//   "Motivational",
//   "Multimedia",
//   "Office Administration",
//   "Optical",
//   "Outdoor Market",
//   "Packaging",
//   "Painting",
//   "Pandemic Related",
//   "Personal Assistant",
//   "Pet Retail",
//   "Pet Services & Grooming",
//   "Photography",
//   "Plastics",
//   "Public Relations / Speaking",
//   "PublicTransportation",
//   "Programmer",
//   "Promoter",
//   "Property Management",
//   "Radio / Production",
//   "Radio / Talent",
//   "Railway / Railroad",
//   "Real Estate",
//   "Recreation",
//   "Recruitment",
//   "Repair",
//   "Research",
//   "Restaurant",
//   "Retail",
//   "Roads / Bridges",
//   "RV Dealership",
//   "RV Dealership Marketing",
//   "RV Sales / Repair",
//   "Sales / Account Rep",
//   "Sales / International",
//   "Sales / Off-Site",
//   "Sales / Telephone",
//   "Sales / Travel",
//   "Sales / Other",
//   "Sanitation / Sanitization",
//   "Science",
//   "School Services",
//   "Secret Shopper",
//   "Security",
//   "Sewing",
//   "Signage / Manufacturing",
//   "Signage / Installation",
//   "Shipping / Receiving",
//   "Small Business Help",
//   "Snow Removal",
//   "Social Distancing",
//   "Social Media Management",
//   "Social Media Content",
//   "Social Services",
//   "Software Development",
//   "Sports",
//   "Sport Sponsorship",
//   "Steel Industry",
//   "Surveyor",
//   "Technology",
//   "Telemarketing",
//   "Television Production",
//   "Television Talent",
//   "Tool & Die",
//   "Training / Coaching",
//   "Translation (Spoken)",
//   "Translation (Signing)",
//   "Transportation",
//   "Tourism",
//   "Utilities",
//   "Veterinarian",
//   "Video Services",
//   "Virtual Assistant",
//   "Virtual Services",
//   "Warehouse",
//   "Waste Management",
//   "Web Services",
//   "WFH / Work From Home",
//   "Other",
// ]

// v1

// export default [
//   "Select a category",
//   "Accounting",
//   "Acting",
//   "Admin / Clerical",
//   "Advertising",
//   "Aerospace",
//   "Agriculture / Farming",
//   "Airline",
//   "Architecture",
//   "Art / Gallery / Museum",
//   "Amusement Parks",
//   "Analytics",
//   "Assembly",
//   "Auction",
//   "Audio & Video Technology",
//   "Author / Blogger",
//   "Automotive / Manufacturing",
//   "Automotive / Retail",
//   "Automotive Supplier",
//   "Automotive / Third Party Services",
//   "Banking / Lending / Finance",
//   "Beauty",
//   "Board Member / Director",
//   "Bookkeeping",
//   "Broadcast",
//   "Business Management",
//   "Campaign",
//   "Car Wash / Detail",
//   "City Appraiser",
//   "City Services",
//   "Cleaning",
//   "Communications",
//   "Computer Sales",
//   "Computer Repair",
//   "Construction / Building",
//   "Construction / Heavy Equip",
//   "Construction / Structural Eng",
//   "Consulting",
//   "Creative",
//   "Crisis Response",
//   "Crowd Control",
//   "Customer Service",
//   "Database Administrator",
//   "Daycare / Childcare",
//   "Delivery",
//   "Dental Office",
//   "Dental Specialist",
//   "Doctor Office",
//   "Door To Door / Sales",
//   "Design",
//   "Digital Print / Print Services",
//   "Distribution",
//   "Disaster Related",
//   "Driver",
//   "Drive-Thru",
//   "Editor / Journalism",
//   "Education / Assistant",
//   "Education / Online Instructor",
//   "Education / Training / Tutor",
//   "Education / Substitute Teacher",
//   "Education / Teacher / Professor",
//   "Elderly & Aging",
//   "Engineering",
//   "Entertainment",
//   "Electrical",
//   "Essential",
//   "Event & Festival",
//   "Executive",
//   "Executive Services",
//   "Faith / Religion / Worship",
//   "Farming",
//   "Finance",
//   "Financial Advisor",
//   "Food / Bar / Banquet",
//   "Food Production",
//   "Fundraising",
//   "Funeral Services",
//   "Gas Station & Convenience",
//   "General Labor",
//   "Government",
//   "Graphic Design",
//   "Grocery",
//   "Gym",
//   "Health",
//   "Heavy Equipment",
//   "Home Services",
//   "Hospitality",
//   "Human Resources",
//   "Human Services",
//   "Illustrator",
//   "Insurance Agent",
//   "Insurance Services",
//   "Installation",
//   "Intermodal / Freight",
//   "Internship",
//   "Interpreter / Sign Language",
//   "Inventory",
//   "Investment",
//   "IT",
//   "Janitorial",
//   "JIT / OTR",
//   "Journalist / Reporter",
//   "Landscaping",
//   "Law Enforcement",
//   "Legal Services",
//   "Logistics",
//   "Machining",
//   "Maintenance",
//   "Mathematical",
//   "Management",
//   "Manufacturing",
//   "Marketing / Promotion",
//   "Market Research",
//   "Martial Arts",
//   "Media",
//   "Medical / Billing",
//   "Medical / Covid",
//   "Medical Essential",
//   "Medical Equipment",
//   "Medical Hospital",
//   "Medical Office",
//   "Medical / Paramedic",
//   "Medical / Nursing",
//   "Medical Specialist",
//   "Medical Supplies",
//   "Medical Testing",
//   "Military / Armed Forces",
//   "Millwright / Welding",
//   "Mining",
//   "Motivational",
//   "Office Administration",
//   "Optical",
//   "Outdoor Market",
//   "Packaging",
//   "Painting",
//   "Pandemic Related",
//   "Personal Assistant",
//   "Pet Retail Services",
//   "Photography",
//   "Public Relations Speaking",
//   "Public Transportation",
//   "Programmer",
//   "Property Management",
//   "Real Estate",
//   "Recreation",
//   "Recruitment",
//   "Research",
//   "Restaurant",
//   "Retail",
//   "Roads / Bridges",
//   "Sales",
//   "Sanitation & Sanitization",
//   "Science",
//   "School Services",
//   "Security",
//   "Sewing Services",
//   "Signage",
//   "Shipping & Receiving",
//   "Small Business Help",
//   "Snow Removal",
//   "Social Distancing",
//   "Social Media",
//   "Social Services",
//   "Software",
//   "Sports",
//   "Structural Engineer / Construct",
//   "Surveyor",
//   "Technology",
//   "Telemarketing",
//   "Training / Coaching",
//   "Transportation",
//   "Tourism",
//   "Utilities",
//   "Veterinarian",
//   "Video Services",
//   "Warehouse",
//   "Waste Management",
//   "Web Services",
//   "WFH / Work From Home",
//   "Other",
// ]
