import React from "react"
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Logo from "../../images/websiteheader.jpg"
import { Divider } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import Email from 'mdi-material-ui/Email';
import Password from 'mdi-material-ui/Lock'
import AccountCircle from 'mdi-material-ui/AccountCircle';
import Briefcase from 'mdi-material-ui/Briefcase';
import MapMarker from 'mdi-material-ui/MapMarker';
import Phone from 'mdi-material-ui/Cellphone';
import City from 'mdi-material-ui/City';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CoverShiftLogo from "../../images/coverShiftz.png"
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as Validation from "./Validation";
import { } from "date-fns/esm";
import Backend from "../Backend";
import { setUser, setBusiness } from "../../redux/actions"
import { useDispatch } from "react-redux"
import states from '../../lists/states';
import Modal from '@material-ui/core/Modal';
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog.js"
import { indigo } from '@material-ui/core/colors';



function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const useStyles = makeStyles(theme => ({

    mainContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
        backgroundColor: "whitesmoke",
        '@media only screen and (max-width: 900px)': {
            backgroundColor: "white"

        }



    },
    registerContainer: {
        width: "570px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        boxShadow: "2px 3px 6px 0px rgba(171,164,171,1)",
        borderRadius: "5px",
        '@media only screen and (max-width: 900px)': {
            width: "100%",
            justifyContent: "center",
            boxShadow: "0px 0px 0px 0px rgba(171,164,171,1)",

        }
    },
    imgContainer: {
        display: "flex",
        justifyContent: "center",
    },
    image: {
        width: "100%",
        '@media only screen and (max-width: 950px)': {

        },
        '@media only screen and (max-width: 950px) and (max-height: 500px)': {

        }
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90%",
        '& > *': {
            marginBottom: "50px",
        }
    },
    columnizeDiv: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        '@media only screen and (max-width: 700px)': {
            display: "flex",
            flexDirection: "column",

        }

    },
    stateForm: {
        width: "60%",
        marginRight: "30px",
        '@media only screen and (max-width: 700px)': {
            width: "100%",
            marginBottom: "50px"

        }
    },
    zipForm: {
        width: "35%",
        '@media only screen and (max-width: 700px)': {
            width: "100%",

        }
    },
    nameForm: {
        width: "45%",
        marginRight: "30px",
        '@media only screen and (max-width: 700px)': {
            width: "100%",
            marginBottom: "50px"
        }
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center'
    },

}));


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#005D98',
        },
        secondary: indigo
    },
})





function Register(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [typeValue, setValue] = React.useState('');
    const [state, setState] = React.useState({
        businessName: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        fname: "",
        lname: "",
        birthDate: new Date(),
        phone: "",
        email: "",
        password: "",
        description: "",
        address2: "",

    });

    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    // modal open
    const handleOpen = () => {
        setOpen(true);
    };
    // modal close 
    const handleClose = async () => {
        setOpen(false)
        await loginAfteRegeristering()
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (checkInputs()) {
            await attemptRegistration()
        }

    }

    const handleType = event => {
        setValue(event.target.value);
    };

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.value });

    };
    const handleDateChange = date => {
        setState({ ...state, birthDate: date })
    };

    function checkInputs() {

        let status = true;
        let errors = []

        if (typeValue === '') {
            status = false
            errors.push("Please select the type of your company")

        }

        if (!Validation.validateEmail(state.email)) {

            status = false;
            errors.push("Please enter a valid Email Address")
        }
        if (!Validation.validatePassword(state.password)) {
            status = false;
            errors.push("Password Must be - At least 8 charaters long, At least have one Uppercase, Lowercase and Digit, No special characters ex- :-/;&$@.")
        }
        if (!Validation.validatePhone(state.phone)) {
            status = false;
            errors.push("Please enter a valid Phone Number. Do not use dashes. eg - 1230001234")
        }
        if (!Validation.validateAddr(state.address)) {
            status = false;
            errors.push("Please enter a valid Address")

        }
        if (!Validation.validateCity(state.city)) {
            status = false;
            errors.push("Please enter a valid City")
        }
        if (!Validation.validateZip(state.zip)) {
            status = false;
            errors.push("Please enter a valid Zip Code")
        }



        if (!status) {
            window.scrollTo(0, 0)
            props.onDisplayError(errors.join(", "))

        }

        return status

    }


    async function attemptRegistration() {
        try {
            let response = await Backend.registerBusinessAccount(state, typeValue);
            let json = await response.json()
            if (!json.success) throw json

            handleOpen()
        } catch (e) {
            props.onDisplayError(e.message || "An unexpected error has occured", e)
        }

    }

    const mapStates = state => <option key={state} value={state}>{state}</option>;

    async function loginAfteRegeristering() {
        try {
            let user = await Backend.userAuth(state.email, state.password)
            if (user.error) throw user;

            dispatch(setUser(user));
            props.history.push('/');
        } catch (error) {
            props.onDisplayError(error.error || error.message || "An unexpected error has occured", error)
        }

    }

    return (


        <div className={classes.mainContainer}>
            <div className={classes.registerContainer}>
                <div className={classes.imgContainer}>
                    <img src={Logo} alt="logo" className={classes.image} />
                </div>
                <div style={{ width: '100%', marginBottom: "70px" }}>
                    <Divider />
                </div>

                <form className={classes.formContainer} onSubmit={handleSubmit}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name="position" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} value={typeValue} onChange={handleType} row>
                                <FormControlLabel
                                    value="business"
                                    control={<Radio color="primary" />}
                                    label="Business"
                                />
                                <FormControlLabel
                                    value="agency"
                                    control={<Radio color="primary" />}
                                    label="Recruiter"
                                />
                            </RadioGroup>
                        </FormControl>

                    </div>

                    <span style={{ fontSize: "20px", textAlign: "center" }}>Legal Business Name / Agency & Recruiter Info</span>

                    <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Legal Business / Agency Name / Recruiter Name"
                        fullWidth
                        required
                        onChange={handleChange('businessName')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Briefcase />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Address"
                        fullWidth
                        required
                        onChange={handleChange('address')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MapMarker />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Apt, Suite #"
                        fullWidth
                        onChange={handleChange('address2')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MapMarker />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="City"
                        fullWidth
                        required
                        onChange={handleChange('city')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <City />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div className={classes.columnizeDiv}>
                        <FormControl required className={classes.stateForm} >
                            <InputLabel htmlFor="age-native-required">State</InputLabel>
                            <Select
                                native
                                value={state.state}
                                onChange={handleChange('state')}
                            >
                                <option value=''></option>
                                {states.map(mapStates)}
                            </Select>

                        </FormControl>

                        <TextField

                            className={classes.zipForm}
                            id="input-with-icon-textfield"
                            label="Zip Code"
                            required
                            onChange={handleChange('zip')}
                        />
                    </div>
                    <span style={{ fontSize: "20px" }}>Legal Representive Info</span>
                    <div className={classes.columnizeDiv}>
                        <TextField

                            className={classes.nameForm}
                            id="input-with-icon-textfield"
                            label="First Name"
                            required
                            onChange={handleChange('fname')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField

                            className={classes.nameForm}
                            id="input-with-icon-textfield"
                            label="Last Name"
                            required
                            onChange={handleChange('lname')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div style={{ width: "60%", }}>
                        <KeyboardDatePicker
                            style={{ width: "100%", }}
                            name="birthday"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Birthday"
                            format="MM/dd/yyyy"
                            value={state.birthDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                    </div>

                    <TextField
                        style={{ width: "60%", }}
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Phone"
                        required
                        onChange={handleChange('phone')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Phone />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField

                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Email"
                        required
                        type="email"
                        fullWidth
                        onChange={handleChange('email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField

                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Password"
                        type="password"
                        required
                        fullWidth
                        onChange={handleChange('password')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Password />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        id="outlined-multiline-static"
                        label="Add a Description"
                        multiline
                        rows="4"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange('description')}
                    />

                    <div style={{ width: "100%", "display": "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        <ThemeProvider theme={theme}>
                            <Button type="submit" variant="contained" color="primary" style={{ width: "100%", marginBottom: "10px" }}>
                                Register
                            </Button>
                        </ThemeProvider>




                    </div>

                </form>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "90%" }}>
                    <span style={{ marginRight: "5px", fontSize: "12px", }}><i>Powered by </i> </span>
                    <img src={CoverShiftLogo} alt="covershiftzLogo" width="10%" />
                </div>
                <div>

                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={open}
                        onClose={handleClose}
                    >
                        <div style={modalStyle} className={classes.paper}>
                            <h3 id="simple-modal-title">Registration Successful </h3>
                            <ThemeProvider theme={theme} >
                                <Button onClick={handleClose} variant="contained" color="primary" style={{ width: "100%", marginBottom: "10px" }}>
                                    Ok
                                </Button>
                            </ThemeProvider>


                        </div>
                    </Modal>
                </div>

            </div>

        </div>
    ) // end return 

}// end register


export default withAlertAndProgressDialog(Register)