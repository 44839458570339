
import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FolderPlusOutline from 'mdi-material-ui/FolderPlusOutline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import AutoComplete from 'material-ui/AutoComplete';
// import Autocomplete from '@material-ui/lab'
import Grid from '@material-ui/core/Grid';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Geosuggest from 'react-geosuggest';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backend from '../Backend';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';
import Typography from '@material-ui/core/Typography';
import categories from '../../lists/categories';
import { useSelector, useDispatch } from 'react-redux';
import { setBusiness } from '../../redux/actions';
import { addMinutesInDate, capitalizeFirstLetter } from '../../utils/functions';
import { Checkbox, FormControlLabel } from '@material-ui/core';
// import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import TextField from '@material-ui/core/TextField';
// import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function DeleteRecruiterDailogue(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleCloseDailogue}
            TransitionComponent={Transition}
            maxWidth='sm'
            fullWidth
            BackdropProps={{ style: { opacity: 0.1, outline: "none" } }}
            style={{ outline: "none" }}

        >
            <DialogTitle><DeleteOutlineIcon />&nbsp;&nbsp;&nbsp; <span>Delete Business</span> </DialogTitle>
            <DialogContent>
                {/* <Alert severity="warning">  </Alert> */}
                <Alert severity="error">
                    Are you sure you want to delete this business account?
                </Alert>
                <p style={{ marginTop: "15px" }}>
                    <b>Note:</b> All the job posts and user associated with this business account will be deleted for good.
                </p>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={props.handleCloseDailogue}>Close</Button>
                <Button color='secondary' onClick={() => props.handleDeleteUser(props.index, props.id)}>Delete Business</Button>
            </DialogActions>
        </Dialog >
    )
}

export default withAlertAndProgressDialog(DeleteRecruiterDailogue);
