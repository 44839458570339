import React from "react"
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Logo from "../../images/websiteheader.jpg"
import CoverShiftLogo from "../../images/coverShiftz.png"
import InputAdornment from '@material-ui/core/InputAdornment';
import { Divider } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { NavLink } from "react-router-dom"
import Email from 'mdi-material-ui/Email';
import Password from 'mdi-material-ui/Lock'
import { useDispatch } from "react-redux"
import { setUser, setBusiness } from "../../redux/actions"
import Backend from "../Backend"
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog.js"
import { indigo } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({

    mainContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        backgroundColor: "whitesmoke",
        '@media only screen and (max-width: 950px)':{
            backgroundColor: "white",
            height: 'auto'
        },
        '@media only screen and (max-width: 950px) and (max-height: 500px)': {
            backgroundColor: "white",
            height: "auto"

        }

    },
    loginContainer: {
        width: "570px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        boxShadow: "2px 3px 6px 0px rgba(171,164,171,1)",
        borderRadius: "5px",
        '@media only screen and (max-width: 950px)': {
            width: "100%",
            height: "auto",
            boxShadow: "0px 0px 0px 0px rgba(171,164,171,1)",
            borderRadius: "0px",

        }
    },
    imgContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "40%"

    },
    image: {
        width: "100%",
        '@media only screen and (max-width: 950px)':{
            
            
        },
        '@media only screen and (max-width: 950px) and (max-height: 500px)': {
           
        }
    },

    formContainer: {
        display: "flex",
        flexDirection: "column",
        width: "90%",
        height: "50%",
        '& > *': {
            marginBottom: "40px",
        }
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
          main: '#005D98',
        },
        secondary: indigo
      },
})


function Login(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        rememberMe: false,
        email: "",
        password: "",
        error: ""
    });

    const handleChange = name => event => {
        [name].toString() === "rememberMe" ? setState({ ...state, [name]: event.target.checked }) : setState({ ...state, [name]: event.target.value })
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        attemptLogin();

    }

    async function attemptLogin() {
        try {
            let user = await Backend.userAuth(state.email, state.password)
            if (user.error) throw user;
            dispatch(setUser(user));
            localStorage.setItem('userPass', state.password)
            props.history.push('/');
        } catch (error) {
            props.onDisplayError(error.error || error.message || "An unexpected error has occured", error)
        }
    }

    return (
        <div className={classes.mainContainer} >
            <div className={classes.loginContainer}>
                <div className={classes.imgContainer}>
                    <img src={Logo} alt="logo" className = {classes.image} />
                </div>
                <div style={{ width: '100%', marginBottom: "30px" }}>
                    <Divider />
                    <div style = {{display: "flex", flexDirection : "column", padding: "17px"}}>
                        <span style = {{textAlign :"center", fontSize : "12px", marginBottom: "5px"}}>Login / Register / Subscribe </span>
                        <span style = {{textAlign :"center", fontSize : "12px"}}>Add and Post jobs (to appear in-app), connect job seekers to your application portal</span>
                    </div>
                </div>           
                <form className={classes.formContainer} onSubmit={handleSubmit}>
                    <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Email"
                        fullWidth
                        type="email"
                        required
                        onChange={handleChange('email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Password"
                        fullWidth
                        required
                        onChange={handleChange('password')}
                        type="password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Password />
                                </InputAdornment>
                            ),
                        }}
                    />
                   
                    <div style={{ width: "100%", "display": "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        <ThemeProvider theme = {theme} >
                            <Button type="submit" variant="contained" color="primary" style={{ width: "100%", marginBottom: "10px" }}>
                                Login
                            </Button>
                        </ThemeProvider>
                        <NavLink to={"/forgetPassword"}> <span style={{ marginRight: "10px", fontSize: "15px" }}>Forgot Password</span></NavLink>
                        <span style={{ marginRight: "10px", fontSize: "15px" }}>|</span>
                        <NavLink to={"/register"} ><span style={{ marginRight: "10px", fontSize: "15px" }}>Register</span> </NavLink>


                    </div>

                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%",  height :"25%" }}>
                        <span style={{ marginRight: "5px", fontSize: "12px", }}><i>Powered by </i> </span>
                        <img src={CoverShiftLogo} alt="covershiftzLogo" width="10%" />
                    </div>

                </form>

            </div>

        </div>

    ) // end return

} // end login


export default withAlertAndProgressDialog(Login)