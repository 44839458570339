
import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FolderPlusOutline from 'mdi-material-ui/FolderPlusOutline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import AutoComplete from 'material-ui/AutoComplete';
// import Autocomplete from '@material-ui/lab'
import Grid from '@material-ui/core/Grid';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Geosuggest from 'react-geosuggest';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backend from '../Backend';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';
import Typography from '@material-ui/core/Typography';
import categories from '../../lists/categories';
import { useSelector, useDispatch } from 'react-redux';
import { setBusiness } from '../../redux/actions';
import { addMinutesInDate, capitalizeFirstLetter } from '../../utils/functions';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
// import TextField from '@material-ui/core/TextField';
// import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function GiftPostDailogue(props) {
    return (
        <div key={props.key} id={props.business.id}>
            <Dialog
                open={props.open}
                onClose={props.handleCloseDailogue}
                TransitionComponent={Transition}
                maxWidth='sm'
                fullWidth
                BackdropProps={{ style: { opacity: 0.1, outline: "none" } }}
                style={{ outline: "none" }}
                key={props.key}
            >
                <DialogTitle><CardGiftcardIcon />&nbsp;&nbsp;&nbsp; <span>Gift Job Posts</span> </DialogTitle>
                <DialogContent>
                    <Alert severity="warning">You're about to gift jobs to<b> {props.business.name} </b> from <b> {props.business.companyName} </b> </Alert>
                    <TextField
                        label='Please write number of job posts that you want to gift'
                        name='giftCount'
                        type='number'
                        InputProps={{ inputProps: { min: 0, } }}
                        style={{ marginTop: "30px" }}
                        onChange={props.inputChanged}
                        value={props.giftCount}
                        key={props.key}
                        fullWidth
                        pattern
                    />

                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={props.handleCloseDailogue}>Close</Button>
                    <Button color='primary' onClick={() => props.handleGiftJobPosts(props.business.id)}>Gift</Button>
                </DialogActions>
            </Dialog >
        </div>
    )
}

export default withAlertAndProgressDialog(GiftPostDailogue);

