import React, { Component } from 'react';
import { Map, TileLayer, Marker, MapControl, Popup, withLeaflet } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

class AddressControl extends MapControl {
    createLeafletElement() {
        const provider = new OpenStreetMapProvider();
        return GeoSearchControl({ provider: provider });
    }
}

class MyMap extends React.Component {
    // ...
    render() {
        const AddressSearch = withLeaflet(AddressControl);
        return (
            <>
                <Map
                    className="map-surface"
                    center={[39, -104]}
                    zoom={5}
                    minZoom={2}
                    ref="map"
                    worldCopyJump={true}
                    zoomControl={false}
                // style={{ opac }}
                >
                    {this.props.children}

                    {/* <ZoomControl
                        position="topright"
                        zoomInTitle="Zoom in"
                        zoomOutTitle="Zoom out"
                    />

                    <ScaleControl
                        className="scale"
                    /> */}

                    <AddressSearch id="abc" />


                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </Map>

                {/* <Map /> */}
            </>
        );
    }
}

export default MyMap;