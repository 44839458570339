import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Menu from 'mdi-material-ui/Menu';
import logo from './images/hireroadlogo.png';
import Briefcase from 'mdi-material-ui/Briefcase';
import Logout from 'mdi-material-ui/Logout';
import AccountCircle from 'mdi-material-ui/AccountCircle';
import grey from '@material-ui/core/colors/grey';
import RouterHandler from './components/RouteHandler';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/authentication/login';
import Register from './components/authentication/Register';
import ForgetPassword from './components/authentication/ForgetPassword';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setBusiness } from './redux/actions';
import SettingsOutline from 'mdi-material-ui/SettingsOutline';
import { Link } from 'react-router-dom';
import CreditCardOutline from 'mdi-material-ui/CreditCardOutline';
import CurrencyUsd from 'mdi-material-ui/CurrencyUsd';
import Backend from './components/Backend';
import Star from 'mdi-material-ui/Star';
import StarOutline from 'mdi-material-ui/StarOutline';
import { withAlertAndProgressDialog } from "./components/hocs/with-alert-and-progress-dialog"
import Bell from 'mdi-material-ui/Bell'
import Remove from 'mdi-material-ui/Delete'
import Add from 'mdi-material-ui/AccountPlus'
// import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StarIcon from '@material-ui/icons/Star';
import crown from "./images/crown.svg"



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: "100%"
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto'
  },
  logo: {
    [theme.breakpoints.up('sm')]: {
      height: 64
    },
    [theme.breakpoints.only('xs')]: {
      height: 48
    }
  }
}));

function App(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const business = useSelector(state => state.business);
  const accInfoRef = useRef(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const updateDrawerHeight = () => {
    setDrawerHeight(window.innerHeight - 48 - accInfoRef?.current?.clientHeight - 1); //height of logout btn - height of account info div - 1px for border
  }

  const getBusiness = async business_id => {
    setLoading(true);
    try {
      let response = await Backend.getBusiness(business_id);
      dispatch(setBusiness(response));
      setLoading(false);
    } catch (err) {
      props.onDisplayError(err.message || 'An error has occurred.', err);
      setLoading(false);
    }
  }

  const getBusinessStars = () => {
    let jsx = [];
    let key = 0;
    for (let i = 0; i < business.rating; i++) {
      jsx.push(<Star key={key++} />);
    }
    for (let i = jsx.length; i < 5; i++) {
      jsx.push(<StarOutline key={key++} />);
    }
    return jsx;
  }
  const drawer = (
    <div>
      <div style={{ backgroundColor: grey[300], padding: 10, textAlign: 'center' }} ref={accInfoRef}>
        {business && (
          <React.Fragment>
            {business.avatar_uri ? <img src={business.avatar_uri} alt='Avatar' style={{ height: 150, width: 150, borderRadius: '50%' }} /> : <AccountCircle style={{ fontSize: 150 }} />}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant='button' component='div' style={{ marginLeft: `${business.premium ? '20px' : 0}` }} >{business.name}</Typography>
              {business.premium ? <img src={crown} style={{ height: "20px", marginLeft: "10px" }} /> : null}

            </div>
            {business.ratings_count > 0 ? <div>{getBusinessStars()}</div> : <Typography variant='caption' component='div'>No ratings</Typography>}
          </React.Fragment>
        )}
      </div>
      <List style={{ height: isNaN(drawerHeight) ? 0 : drawerHeight, overflow: 'auto' }}>
        {!user?.admin && <ListItem button component={Link} to='/posted_jobs'>
          <ListItemIcon>
            <Briefcase />
          </ListItemIcon>
          <ListItemText primary='All Jobs' />
        </ListItem>}
        {!user?.admin && <ListItem button component={Link} to='/payment_methods'>
          <ListItemIcon>
            <CreditCardOutline />
          </ListItemIcon>
          <ListItemText primary='Payment Methods' />
        </ListItem>}
        {!user?.admin && <ListItem button component={Link} to='/buy_posts'>
          <ListItemIcon>
            <CurrencyUsd />
          </ListItemIcon>
          <ListItemText primary='Buy Posts' />
        </ListItem>}
        {!user?.admin && <ListItem button component={Link} to='/settings'>
          <ListItemIcon>
            <SettingsOutline />
          </ListItemIcon>
          <ListItemText primary='Settings' />
        </ListItem>}
        {user?.admin &&
          <div>
            {/*<Divider />*/}
            <p style={{ textAlign: "center", fontWeight: 'bold', fontSize: 'initial' }}>Admin Options</p>
          </div>}

        {user?.admin && <ListItem button component={Link} to='/send_notifications'>
          <ListItemIcon>
            <Bell />
          </ListItemIcon>
          <ListItemText primary='Send Notifications' />
        </ListItem>}
        {user?.admin && <ListItem button component={Link} to='/view-business'>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary='Recruiter/Business' />
        </ListItem>}
        {/* {user?.admin && <ListItem button component={Link} to='/remove'>
          <ListItemIcon>
            <Remove />
          </ListItemIcon>
          <ListItemText primary='Remove Business' />
        </ListItem>} */}
        {user?.admin && <ListItem button component={Link} to='/add_admins'>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary='Add Admins' />
        </ListItem>}
        {user?.admin && <ListItem button component={Link} to='/settings'>
          <ListItemIcon>
            <SettingsOutline />
          </ListItemIcon>
          <ListItemText primary='Settings' />
        </ListItem>}

      </List>
      <Divider />
      <List disablePadding>
        <ListItem button onClick={() => {
          dispatch(setUser(null));
          dispatch(setBusiness(null));
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          localStorage.removeItem('userPass') // needed for reseting pass 
        }}>
          <ListItemIcon><Logout /></ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItem>
      </List>
    </div>
  );

  useEffect(() => {
    updateDrawerHeight();
  }, [accInfoRef.current?.clientHeight])

  useEffect(() => {
    if (user)
      getBusiness(user.business_id);
  }, [user])

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) dispatch(setUser(JSON.parse(storedUser)));

    window.addEventListener("resize", updateDrawerHeight);
    return () => {
      window.removeEventListener("resize", updateDrawerHeight);
    }
    // eslint-disable-next-line
  }, [])
  // const user = localStorage.getItem('user')

  if (!localStorage.getItem('user')) {
    return (
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/register' component={Register} />
        <Route path='/forgetPassword' component={ForgetPassword} />
        <Route path='/' render={() => <Redirect to='/login' />} />
      </Switch>
    )
  }




  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color='inherit'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            HireRoad
          </Typography>
          <div style={{ flex: 1 }}></div>
          <img src={logo} alt='HireUp' className={classes.logo} />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <RouterHandler />
      </main>
      <Switch>
        <Route path="/login" render={() => <Redirect to='/' />} />
        <Route path='/register' render={() => <Redirect to='/' />} />
        <Route path='/forgetPassword' render={() => <Redirect to='/' />} />
      </Switch>
    </div>
  );
}

export default withAlertAndProgressDialog(App);