import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AccountCircle from 'mdi-material-ui/AccountCircle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Divider } from '@material-ui/core';
import Backend from '../Backend';
import { useDispatch, useSelector } from 'react-redux';
import { setBusiness } from '../../redux/actions';
import states from '../../lists/states';
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog.js"
import LinearProgress from '@material-ui/core/LinearProgress';

function EditProfileDialog(props) {
    const [loading, setLoading] = useState(false);
    const businessInfo = useSelector(state => state.business);
    const [state, setState] = useState({});
    const dispatch = useDispatch();

    const inputChanged = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        await attemptUpdateProfile()

    }

    const mapStates = state => <option key={state} value={state}>{state}</option>;

    async function attemptUpdateProfile() {
        setLoading(true)
        try {
            let response = await Backend.business_SaveProfile(state)
            let json = await response.json()
            if (!json.success) throw json();
            const {
                message,
                business
            } = json
            setLoading(false)
            dispatch(setBusiness(business));
            props.onDisplayAlert(message, "Success")


        } catch (exception) {
            setLoading(false)
            props.onDisplayError(exception.message || 'An unexpected error has occurred.', exception)

        }
    }

    useEffect(() => {
        if (businessInfo)
            setState({
                address: businessInfo.address,
                address2: businessInfo.address2,
                city: businessInfo.city,
                state: businessInfo.state,
                zip: businessInfo.zip,
                manager_fname: businessInfo.manager_fname,
                manager_lname: businessInfo.manager_lname,
                phone: businessInfo.phone
            })
    }, [businessInfo])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth='sm'
            fullWidth
        >
            <form onSubmit={handleSubmit} >
                <DialogTitle>
                    {loading && <LinearProgress />}
                    <span style={{ position: "relative", top: "7.1px" }} ><AccountCircle /> </span>
                    &nbsp;&nbsp;&nbsp;Edit Profile</DialogTitle>
                <div style={{ width: "100%", marginBottom: "20px" }}>
                    <Divider />
                </div>
                <DialogContent>
                    <Grid container spacing={2}>
                        <span>General Info</span>
                        <Grid item xs={12}>
                            <TextField
                                label='Address'
                                name='address'
                                value={state.address ?? ''}
                                onChange={inputChanged}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Apt, Suite #'
                                name='address2'
                                value={state.address2 ?? ''}
                                onChange={inputChanged}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='City'
                                name='city'
                                value={state.city ?? ''}
                                onChange={inputChanged}
                                multiline
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl required style={{ width: "100%" }} >
                                <InputLabel htmlFor="age-native-required">State</InputLabel>
                                <Select
                                    native
                                    value={state.state}
                                    name="state"
                                    onChange={inputChanged}

                                >
                                    <option value=""></option>
                                    {states.map(mapStates)}
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label='Zip Code'
                                name='zip'
                                value={state.zip ?? ''}
                                onChange={inputChanged}
                                multiline
                                fullWidth
                                required
                            />
                        </Grid>

                        <div style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}>
                            <Divider />
                        </div>

                        <span>Legal Representative Info</span>
                        <Grid item xs={12}>
                            <TextField
                                label='First Name'
                                name='manager_fname'
                                value={state.manager_fname ?? ''}
                                onChange={inputChanged}
                                multiline
                                fullWidth
                                required

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Last Name'
                                name='manager_lname'
                                value={state.manager_lname ?? ''}
                                onChange={inputChanged}
                                multiline
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Phone'
                                name='phone'
                                value={state.phone ?? ''}
                                onChange={inputChanged}
                                multiline
                                fullWidth
                                required
                            />
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={props.onClose}>Close</Button>
                    <Button type="submit" color='primary'>Submit{loading && <React.Fragment>&nbsp;<CircularProgress size={20} /></React.Fragment>}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default withAlertAndProgressDialog(EditProfileDialog);