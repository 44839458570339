import { SET_USER, SET_BUSINESS } from "./actionTypes";

function setUser(payload) {
    return {
        type: SET_USER,
        payload
    }
}

function setBusiness(payload) {
    return {
        type: SET_BUSINESS,
        payload
    }
}

export { setUser, setBusiness };