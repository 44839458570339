import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Backend from "../Backend";
// import Button from '@material-ui/core/Button';
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog";
import Grid from "@material-ui/core/Grid";
import { LinearProgress } from "@material-ui/core";
import login from "../authentication/login";
import "./apllicant.css"
// import InputAdornment from '@material-ui/core/InputAdornment';
// import SearchIcon from '@material-ui/icons/Search';

function createData(name, address, phone, resumeUrl, email, applied_date, id, linkedin, videoResumeUrl) {
  return { name, address, phone, resumeUrl, email, applied_date, id, linkedin, videoResumeUrl };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "address", numeric: false, disablePadding: false, label: "Address" },
  { id: "phone", numeric: true, disablePadding: false, label: "Phone #" },
  // { id: "veteran", numeric: true, disablePadding: false, label: "Veteran" }, //
  {
    id: "resumeUrl",
    numeric: true,
    disablePadding: false,
    label: "Resume URL",
  },
  { id: "videoResumeUrl", numeric: true, disablePadding: false, label: "Video Resume Url" }, //
  { id: "linkedin", numeric: true, disablePadding: false, label: "Linkedin" }, //
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "applied_date",
    numeric: false,
    disablePadding: false,
    label: "Application Date",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id == "phone" || headCell.id == "resumeUrl" || headCell.id == "videoResumeUrl" || headCell.id == "applied_date" ? headCell.label : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, query, setQuery, handleChangeSearch } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        // [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Applicants
      </Typography>
      <Grid sm={3}>
        <TextField
          label="Search"
          value={query}
          onChange={handleChangeSearch}
          fullWidth
        />
      </Grid>
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//     numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function ViewApplicants(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [query, setQuery] = useState("");
  const [rows, setRows] = useState([]);
  const [applicantsResponse, setApplicantsResponse] = useState([])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // SEARCH COMPONENT
  const handleChangeSearch = (e) => {
    // const searchInput = e.target.value;
    setQuery(e.target.value);
    let filteredData = applicants.filter((value) => {
      const name = value.fname + " " + value.lname;
      const workerAddress = `${value.address2 + " " + value.address + " " + value.city + " " + value.state + " " + value.zip}`
      return (
        name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        workerAddress?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        value?.phone?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        value?.resumeUrl
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        value?.videoResumeUrl
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        value?.linkedin
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        // value?.birthday
        //   .toLowerCase()
        //   .includes(e.target.value.toLowerCase()) ||
        value?.user_docs[0]?.email?.toLowerCase().includes(e.target.value.toLowerCase())
        // value.applied_date.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setFilteredApplicants(filteredData);
  };
  useEffect(() => {
    let workerAddress;
    if (query) {
      setPage(0);
      setRows(
        filteredApplicants?.map((val) => {
          workerAddress = `${val.address2 + " " + val.address + " " + val.city + " " + val.state + " " + val.zip}`
          createData(
            val.fname + " " + val.lname,
            workerAddress,
            val.phone,
            val.resumeUrl,
            val.user_docs[0].email,
            val.applied_date,
            val._id,
            val.linkedin,
            val.videoResumeUrl
          )
        }
        )
      );
    } else {
      setRows(
        applicants.map((val) => {
          workerAddress = `${val.address2 + " " + val.address + " " + val.city + " " + val.state + " " + val.zip}`
          return createData(
            val.fname + " " + val.lname,
            workerAddress,
            val.phone,
            val.resumeUrl,
            val.user_docs[0].email,
            val.applied_date,
            val._id,
            val.linkedin,
            val.videoResumeUrl
          )
        }
        )
      );
    }
  }, [applicants, query]);
  // SEARCH COMPONENT ENDS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //     setDense(event.target.checked);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // let applicantsResponse;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  useEffect(() => {
    // console.log(props.location.state)
    setApplicantsResponse(props.location.state)
    const applicantsArray = props?.location?.state?.map((item) => item.id);
    handleGetApplicants(applicantsArray);
  }, []);
  const handleGetApplicants = async (applicantsArray) => {
    try {
      setLoading(true);
      let response = await Backend.listApplicants(applicantsArray);
      let res = await response.json();

      if (!res.success) throw res.message;

      let workers = res.applicants;
      workers.forEach((worker) => {
        worker["applied_date"] = props?.location?.state.find(
          (item) => item.id === worker._id
        ).applied_date;
      });
      setQuery("")
      setApplicants(workers);
      setRows(workers);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      props.onDisplayError(
        e.error || e.message || "An unexpected error has occured",
        e
      );
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleGiftPostDailogue = () => {
    setOpen(true);
  };
  const handleCloseDailogue = () => {
    setOpen(false);
  };


  const ellipsisStyle = {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // width: "350px",
    maxWidth: "200px",
  };

  return (
    <div className={classes.root} style={{ padding: "0 35px" }}>
      <Paper className={classes.paper}>
        {loading && <LinearProgress />}
        <EnhancedTableToolbar
          query={query}
          setQuery={setQuery}
          handleChangeSearch={handleChangeSearch}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
            data={applicants}
          >
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {rows.length ? (
              <TableBody>
                {/*To be Uncommented*/}

                {/*{stableSort(rows, getComparator(order, orderBy))*/}
                {/*    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}
                {/*    .map((row, index) => {*/}
                {/*        // const isItemSelected = isSelected(row.name);*/}
                {/*        const labelId = `enhanced-table-checkbox-${index}`;*/}

                {/*        return (*/}
                {/*            <TableRow*/}
                {/*                hover*/}
                {/*                // onClick={(event) => handleClick(event, row.name)}*/}
                {/*                role="checkbox"*/}
                {/*                // aria-checked={isItemSelected}*/}
                {/*                tabIndex={-1}*/}
                {/*                key={row._id}*/}
                {/*                // selected={isItemSelected}*/}
                {/*            >*/}
                {/*                /!* <TableCell padding="checkbox">*/}
                {/*                    <Checkbox*/}
                {/*                        checked={isItemSelected}*/}
                {/*                        inputProps={{ 'aria-labelledby': labelId }}*/}
                {/*                    />*/}
                {/*                </TableCell> *!/*/}
                {/*                <TableCell component="th" id={labelId} scope="row">{row.name}</TableCell>*/}
                {/*                <TableCell align="left">{row.address}</TableCell>*/}
                {/*                <TableCell align="left">{row.phoneNo}</TableCell>*/}
                {/*                <TableCell align="left">{row.resumeUrl}</TableCell>*/}
                {/*                <TableCell align="left">{row.dob}</TableCell>*/}
                {/*                <TableCell align="left">{row.linkedin}</TableCell>*/}
                {/*            </TableRow>*/}
                {/*        );*/}
                {/*    })}*/}

                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    // const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.index}
                      // selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell> */}
                        <TableCell component="th" id={labelId} scope="row" className="other-fields">
                          {row.name ? row.name : <span>-</span>}
                        </TableCell>
                        <TableCell
                          className="address-elipse"
                          align="left"
                        // style={{ ...ellipsisStyle }}
                        >
                          {row.address ? row.address : <span>-</span>}
                        </TableCell>
                        <TableCell className="other-fields" align="left">{row.phone ? row.phone : <span>-</span>}</TableCell>
                        {/* <TableCell style={{ ...ellipsisStyle }} align="left">Static</TableCell> */}
                        <TableCell
                          align="left"
                          style={{ ...ellipsisStyle }}
                        >
                          <a href={row.resumeUrl} target={"_blank"}>
                            {row.resumeUrl ? row.resumeUrl : <span>-</span>}
                          </a>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ ...ellipsisStyle }}
                        >
                          <a href={row.videoResumeUrl} target={"_blank"}>
                            {row.videoResumeUrl ? row.videoResumeUrl : <span>-</span>}
                          </a>
                        </TableCell>
                        <TableCell align="left" className="other-fields">{row.linkedin ? row.linkedin : <span>-</span>}</TableCell>
                        <TableCell align="left" className="other-fields" > {row.email ? row.email : <span>-</span>}</TableCell>
                        <TableCell align="left" className="other-fields">{new Date(row.applied_date).toLocaleDateString('en-US')}</TableCell>
                      </TableRow>
                    );
                  })}
                {/* {Date().toLocaleDa} */}

                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
                null
              )}
          </Table>
        </TableContainer>
        {rows.length <= 0 && <h3 style={{ padding: "0 15px", fontSize: "x-large", textAlign: "center" }}>
          No Applicants For This Job
                </h3>}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
    </div >
  );
}

export default withAlertAndProgressDialog(ViewApplicants);
