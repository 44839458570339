import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...passedProps }) => {
  const user = useSelector(state => state.user);
  return (
    <Route
      {...passedProps}
      render={props =>
        user?.admin ? (
          <Component {...props} key={window.location.pathname} />
        ) : (
            <Redirect to="/" />
          )
      }
    />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AdminRoute;
