import React, { useState } from 'react';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog"
import { Container, LinearProgress, Typography, Radio, RadioGroup, FormControl, FormControlLabel, Divider, TextField, InputAdornment, Button } from '@material-ui/core';
import Subject from 'mdi-material-ui/TextSubject';
import Backend from '../Backend'
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: "20px"
  }
}));
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#005D98',
    },
    secondary: red
  },
})

function SendNotifications(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [sendType, setSendType] = useState('')
  const [notif, setNotif] = useState({
    title: '',
    message: '',
    style: 'info',
    type: sendType,
    action: "NoNavigation",
    data: {},
  })

  /* useEffect(() => {
     if(!localStorage.getItem('admin')){
       props.history.push('/adminLogin');
     }
   },[])*/

  const handleSendType = e => {
    setSendType(e.target.value)
    setNotif({ ...notif, type: e.target.value })
  }

  const handleChange = name => e => {
    setNotif({ ...notif, [name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (sendType === '') throw sendType
      attemptToSend()
    } catch (error) {
      props.onDisplayError("Please Select a Reciever")
    }

  }

  async function attemptToSend() {
    try {
      setLoading(true)
      let response = await Backend.sendMassNotifications(notif)
      let res = await response.json()
      if (!res.success) throw res
      setLoading(false)
      props.onDisplayAlert(res.message, "Success")
    } catch (e) {
      setLoading(false)
      props.onDisplayError(e.error || e.message || "An unexpected error has occured", e)

    }


  }



  return (

    <Container>
      <Paper style={{ padding: 10 }}>
        {loading && <LinearProgress />}
        <Typography variant="h6"> Send Notifications </Typography>
        <Divider className='divider-margin' />

        <form className="notifForm" onSubmit={handleSubmit}>
          <Typography variant="h6" style={{ textAlign: "center" }}>Send To</Typography>
          <div className="options" style={{ display: "flex", justifyContent: "center" }}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="position" name="position" value={sendType} onChange={handleSendType} row style={{ display: "flex", justifyContent: 'center' }}>
                <FormControlLabel
                  value="worker"
                  control={<Radio color="primary" />}
                  label="Workers"
                />
                <FormControlLabel
                  value="business"
                  control={<Radio color="primary" />}
                  label="Business/Agency"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label="All"
                />
              </RadioGroup>
            </FormControl>

          </div>
          <Divider className='divider-margin' />

          <div>
            <TextField
              className={classes.margin}
              id="input-with-icon-textfield"
              label="Title"
              placeholder="Enter a Title"
              fullWidth
              onChange={handleChange('title')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Subject />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              className={classes.margin}
              id="outlined-multiline-static"
              label="Enter a Message"
              multiline
              rows="4"
              fullWidth
              required
              variant="outlined"
              onChange={handleChange('message')}

            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ThemeProvider theme ={theme}>
              <Button type="submit" variant="outlined" color="primary" style={{ width: "50%", marginBottom: "10px" }}>
                Send Notification
             </Button>
            </ThemeProvider>

          </div>

        </form>
      </Paper>
    </Container>

  );
}

export default withAlertAndProgressDialog(SendNotifications)