import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const BusinessRoute = ({ component: Component, ...passedProps }) => {
    const user = useSelector(state => state.user);
    return (
        <Route
            {...passedProps}
            render={props =>
                user?.admin == false && user?.business_id ? (
                    <Component {...props} key={window.location.pathname} />
                ) : (
                        <Redirect to="/" />
                    )
            }
        />
    );
};

BusinessRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
};

export default BusinessRoute;
