import React, { useState, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ChevronDown from 'mdi-material-ui/ChevronDown';
import CircularProgress from "@material-ui/core/CircularProgress";
import useTheme from "@material-ui/core/styles/useTheme";

function withAlertAndProgressDialog(WrappedComponent) {
  return function (props) {
    const theme = useTheme();
    const [alertState, setAlertState] = useState({
      open: false,
      title: "",
      message: "",
      error: null,
      isList: false
    });
    const [progressState, setProgressState] = useState({
      open: false,
      message: ""
    });

    const displayAlert = useCallback((message, title) => {
      setAlertState(current => ({
        ...current,
        open: true,
        title,
        message,
        isList: false
      }));
    }, []);

    const displayError = useCallback((message, error) => {
      setAlertState(current => ({
        ...current,
        open: true,
        title: "Error",
        error,
        message,
        isList: true
      }));
    }, []);

    const displayProgress = useCallback(message => {
      setProgressState({
        open: true,
        message: message || "Loading, please wait..."
      });
    }, []);

    const closeAlertDialog = useCallback(() => {
      setAlertState(current => ({
        ...current,
        open: false,
        error: null
      }));
    }, []);

    const closeProgressDialog = useCallback(() => {
      setProgressState(current => ({
        ...current,
        open: false
      }));
    }, []);

    return (
      <React.Fragment>
        <WrappedComponent
          {...props}
          onDisplayAlert={displayAlert}
          onDisplayError={displayError}
          onDisplayProgress={displayProgress}
          onCloseProgress={closeProgressDialog} />
        <Dialog
          open={alertState.open}
          onClose={closeAlertDialog}
          maxWidth='sm'
          fullWidth>
          <DialogTitle>{alertState.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {alertState.message}
            </DialogContentText>
            {alertState.error && (
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ChevronDown />}>Developer Details</ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ overflow: 'auto' }}>
                  <pre>
                    {JSON.stringify(alertState.error, null, 2)}
                  </pre>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={closeAlertDialog}>OK</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={progressState.open}
          onClose={closeProgressDialog}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='sm'
          fullWidth>
          <DialogContent style={{ textAlign: "center" }}>
            <DialogContentText>{progressState.message}</DialogContentText>
            <CircularProgress style={{ marginTop: theme.spacing(2) }} size={50} />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export {
  withAlertAndProgressDialog
};