import React, { useState } from 'react';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Container, LinearProgress, Typography, Divider, TextField, InputAdornment, Button } from '@material-ui/core';
import Email from 'mdi-material-ui/Email';
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog"
import Backend from '../Backend'
import { red } from '@material-ui/core/colors';



const useStyles = makeStyles(theme => ({

}));
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#005D98',
        },
        secondary: red
    },
})

function AddAdmins(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')


    const handleChange = name => e => {
        setEmail(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        attemptAddingAdmin()
    }

    async function attemptAddingAdmin() {
        try {
            setLoading(true)
            let response = await Backend.add_admin(email)
            let res = await response.json()
            if (!res.success) throw res
            setLoading(false)
            props.onDisplayAlert(res.message, "Success")

        } catch (e) {
            setLoading(false)
            props.onDisplayError(e.error || e.message || "An unexpected error has occured", e)
        }
    }


    return (
        <Container>
            <Paper style={{ padding: 10 }}>
                {loading && <LinearProgress />}
                <Typography variant="h6"> Add Admins </Typography>
                <Divider className='divider-margin' />
                <form className="AddAdminForm" onSubmit={handleSubmit} style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ display: "flex", width: "75%", marginBottom: "20px" }}>
                        <TextField
                            className={classes.margin}
                            id="input-with-icon-textfield"
                            label="Email"
                            type='email'
                            placeholder="Enter a Registered Email"
                            fullWidth
                            required
                            onChange={handleChange('email')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <ThemeProvider theme={theme}>
                        <Button type="submit" variant="outlined" color="primary" style={{ width: "30%", marginBottom: "10px" }}>
                            Add Admin
                        </Button>
                    </ThemeProvider>

                </form>
            </Paper>
        </Container>
    );
}

export default withAlertAndProgressDialog(AddAdmins);