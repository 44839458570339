import React, { useState } from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backend from '../Backend';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withAlertAndProgressDialog } from '../hocs/with-alert-and-progress-dialog';

function CardForm(props) {
  const [loading, setLoading] = useState(false);

  const addCard = async () => {
    setLoading(true);
    try {
      let { token } = await props.stripe.createToken();
      let response = await Backend.business_LinkCard(token?.id);
      let data = await response.json();
      if (data.success) props.getStripeAccount();
      else throw data;
      setLoading(false);
    } catch (err) {
      setLoading(false);
      props.onDisplayError(err.message || 'An unexpected error has occurred.', err);
    }
  }

  return (
    <Grid container spacing={1} alignItems='center'>
      <Grid item style={{ flex: 1 }}>
        <CardElement />
      </Grid>
      <Grid item>
        <Button variant='outlined' color='primary' onClick={addCard}>Add{loading && <React.Fragment>&nbsp;<CircularProgress size={20} /></React.Fragment>}</Button>
      </Grid>
    </Grid>
  )
}

export default injectStripe(withAlertAndProgressDialog(CardForm));