import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FolderPlusOutline from "mdi-material-ui/FolderPlusOutline";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import AutoComplete from 'material-ui/AutoComplete';
// import Autocomplete from '@material-ui/lab'
import Grid from "@material-ui/core/Grid";
import {
  KeyboardDateTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Geosuggest from "react-geosuggest";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backend from "../Backend";
import { withAlertAndProgressDialog } from "../hocs/with-alert-and-progress-dialog";
import Typography from "@material-ui/core/Typography";
import categories from "../../lists/categories";
import { useSelector, useDispatch } from "react-redux";
import { setBusiness } from "../../redux/actions";
import { addMinutesInDate, capitalizeFirstLetter } from "../../utils/functions";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./createJob.css";

const useStyles = makeStyles((theme) => ({
  textFieldHeight: {
    "& .MuiInputBase-inputMultiline": {
      height: "1.2em",
    },
    "& .MuiInputBase-inputMultiline:focus": {
      height: "auto",
    },
  },
}));

function CreateJobDialog(props) {
  const classes = useStyles();
  const business = useSelector((state) => state.business);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    title: null,
    description: {
      attire: null,
      task: null,
    },
    job_type: "part-time",
    external_application_href: null,
    start_time: new Date(),
    // end_time: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    end_time: new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate(),
      23,
      59,
      59,
      0
    ),
    geometry: null,
    location: null,
    category: null,
    hourly_rate: null,
    annual_salary: null,
    benefits: null,
    remote: false,
  });
  const [suggestionList, setSuggestionList] = useState([]);

  useEffect(() => {
    if (props.open && props.repost) {
      setState({
        title: props.job.title ?? null,
        description: {
          attire: props.job.description?.attire ?? null,
          task: props.job.description?.task ?? null,
        },
        job_type: props.job.job_type ?? "part-time",
        external_application_href: props.job.external_application_href ?? null,
        start_time: new Date(),
        end_time: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate(),
          23,
          59,
          59,
          0
        ),
        geometry: null,
        location: props.job.location ?? null,
        category: props.job.category ?? null,
        hourly_rate: props.job.hourly_rate ?? null,
        annual_salary: props.job.hourly_rate ?? null,
        benefits: null,
        remote: false,
      });
    } else if (props.open) {
      setState({
        title: null,
        description: {
          attire: null,
          task: null,
        },
        job_type: "part-time",
        external_application_href: null,
        start_time: new Date(),
        end_time: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate(),
          23,
          59,
          59,
          0
        ),
        geometry: null,
        location: null,
        category: null,
        hourly_rate: null,
        annual_salary: props.job.hourly_rate ?? null,
        benefits: null,
        remote: false,
      });
      setSuggestionList([]);
    }
  }, [props.open]);
  useEffect(() => {
    if (state.title) {
      setState({ ...state, title: capitalizeFirstLetter(state.title) });
    }
  }, [state.title]);
  // console.log(new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate(), 23, 59, 59, 0), "eeeeee")
  useEffect(() => {
    // if (e.target.name == "job_type") {
    if (state.job_type == "pay-based-on-experience") {
      setState({ ...state, hourly_rate: null, annual_salary: null });
    } else if (state.job_type == "annual_salary") {
      setState({ ...state, hourly_rate: null });
    } else if (state.job_type == "part-time") {
      setState({ ...state, annual_salary: null });
    } else if (state.job_type == "full-time") {
      setState({ ...state, annual_salary: null });
    }

    // }
  }, [state.job_type]);

  const inputChanged = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleChangeRemote = (e) => {
    // console.log(e.target.checked, "checkbox")
    setState({ ...state, remote: e.target.checked });
  };

  const handleDateChange = (prop) => (date) => {
    // if (!date) setState({ ...state, [prop]: date });
    if (!date) setState({ ...state, [prop]: date });
    // return;
    else {
      date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        0
      );
      // console.log(date, "date");
      // console.log(date)
      setState({ ...state, [prop]: date });
    }
  };
  // console.log(state.end_time, "date in state")
  const onSuggestSelect = (suggestion) => {
    // alert("abc")
    // console.log(suggestion, "suggestion")
    if (!suggestion)
      return setState({ ...state, geometry: null, location: null });
    const geometry = state.geometry
      ? { ...state.geometry }
      : { type: "Point", coordinates: [] };
    geometry.coordinates = [
      suggestion?.location?.lng,
      suggestion?.location?.lat,
    ];
    setState({ ...state, geometry, location: suggestion?.label });
  };

  const descriptionChanged = (e) => {
    setState({
      ...state,
      description: { ...state.description, [e.target.name]: e.target.value },
    });
  };

  // console.log(state.end_time.getMonth(), state.end_time.getDate(), state.end_time.getFullYear(), "date in state")
  const createJobPost = async () => {
    setLoading(true);
    // console.log(new Date(state.end_time));
    try {
      if (!checkTitle()) throw "Please write Title";
      if (!checkJobType()) throw "Please select Job Type";
      if (!checkLink()) throw "Please enter a valid link";
      if (!checkEndDate()) throw "Please Select Expire Date";
      // if (!checkDate()) throw "Invalid End Date. Please select end date 1 hour afterwards"
      if (!checkLocation()) throw "Please select Location";
      if (!checkCategory()) throw "Please Select a Category";
      let response = await Backend.createJobpost(state);
      let data = await response.json();
      if (!data.success) throw data;
      let updatedBusiness = {
        ...business,
        posts_remaining: business.posts_remaining - 1,
      };
      dispatch(setBusiness(updatedBusiness));
      setLoading(false);
      props.addJobPost(data.jobpost);
      props.onClose();
    } catch (err) {
      setLoading(false);
      props.onDisplayError(
        err.message || "An unexpected error has occurred.",
        err
      );
    }
  };
  const mapCategories = (category) => {
    return (
      <option key={category} value={category}>
        {category}
      </option>
    );
  };

  const checkTitle = () => {
    if (state.title) {
      return true;
    }
    return false;
  };
  const checkJobType = () => {
    if (state.job_type) {
      return true;
    }
    return false;
  };
  const checkLocation = () => {
    if (state.location) {
      return true;
    }
    return false;
  };

  const checkDate = () => {
    const startTime = new Date(state.start_time);
    const endTime = new Date(state.end_time);
    // console.log(state.end_time.getTime(), new Date().setHours(new Date().getHours() + 1), "abc")
    // if (endTime.getTime() > startTime.setHours(startTime.getHours() + 1)) {
    //   if (endTime.getTime() < startTime.setHours(startTime.getHours() + 1)) {
    // console.log(endTime < endTime.setMonth(endTime.getMonth() + 1), "date checl", endTime, "start", startTime.setMonth(startTime.getMonth() + 1))
    if (endTime > addMinutesInDate(startTime, 59)) {
      // if (startTime > endTime.setMonth(endTime.getMonth() + 1)) {
      //   return false
      // }
      return true;
    } else {
      return false;
    }
  };

  const checkLink = () => {
    if (
      state.external_application_href === null ||
      state.external_application_href === ""
    ) {
      return false;
    } else if (
      !state?.external_application_href.includes("https://") &&
      !state?.external_application_href.includes("http://")
    ) {
      return false;
    } else {
      return true;
    }
  };
  const checkCategory = () => {
    if (state.category) {
      return true;
    }
    return false;
  };
  const checkEndDate = () => {
    if (state.end_time) {
      return true;
    }
    return false;
  };
  const handleSearch = async (e) => {
    if (e?.target) {
      let response = await Backend.SuggestPlace(e.target.value);
      // console.log(response.features, "response")
      setSuggestionList(response.features);
    }
    // alert("abc")
    // console.log(target.value)
    // let data = await response.json();
  };
  const handleSelectSuggestion = (e, value) => {
    // const { geometry} = value
    // console.log(value, "value")
    if (!value) return setState({ ...state, geometry: null, location: null });
    const geometry = state.geometry
      ? { ...state.geometry }
      : { type: "Point", coordinates: [] };
    geometry.coordinates = [
      value?.geometry?.coordinates[0] && value?.geometry?.coordinates[0],
      value?.geometry?.coordinates[1] && value?.geometry?.coordinates[1],
    ];
    let country;
    let county;
    let district;
    let name;
    let keyword;
    const properties = value?.properties;
    const type = properties?.type;
    let osm_value;
    if (properties) {
      if (properties.osm_value === "locality") osm_value = properties.city;
      else if (
        properties.osm_value !== "yes" &&
        properties.osm_key !== "building"
      ) {
        osm_value = properties.osm_value;
      } else if (
        properties.osm_value === "yes" &&
        properties.osm_key !== "building"
      ) {
        osm_value = properties.osm_key;
      } else osm_value = properties.city ? properties.city : properties.county;

      country = properties.country ? properties.country : "";
      name = properties.name ? properties.name : properties.street;
      keyword = `${name}, ${osm_value}, ${country}`;
    }
    setState({
      ...state,
      geometry,
      location: keyword
    });
  };
  // console.log(state, "state")
  // switch (type) {
  //   case 'country':
  //     keyword = option.properties.name

  //     break;
  //   case 'city':
  //     keyword = option.properties.name
  //     break;

  //   case 'house':
  //     keyword = option.properties.housenumber
  //     break

  //   default:
  //     keyword = option.properties.name
  //     break;
  // }
  const handleOptions = (option) => {
    let country;
    let county;
    let district;
    let name;
    let keyword;
    const properties = option?.properties;
    const type = properties?.type;

    // ******** Working Switch Case ********

    // switch (type) {
    //   case 'country':
    //     country = option.properties.name
    //     break;
    //   case 'city':
    //     keyword = option.properties.name
    //     break;
    //
    //   case 'house':
    //     keyword = option.properties.name ? option.properties.name : option.properties.housenumber
    //     break
    //
    //   default:
    //     keyword = option.properties.name
    //     break;
    // }

    let osm_value;
    if (properties) {
      if (properties.osm_value === "locality") osm_value = properties.city;
      else if (
        properties.osm_value !== "yes" &&
        properties.osm_key !== "building"
      ) {
        osm_value = properties.osm_value;
      } else if (
        properties.osm_value === "yes" &&
        properties.osm_key !== "building"
      ) {
        osm_value = properties.osm_key;
      } else osm_value = properties.city ? properties.city : properties.county;

      country = properties.country ? properties.country : "";
      name = properties.name ? properties.name : properties.street;
      keyword = `${name}, ${osm_value}, ${country}`;
    }

    // if(option.properties.osm_value === 'house' || option.properties.osm_value === 'yes') {
    //   let osm_value = option.properties.osm_value ? option.properties.osm_value : undefined;
    //   country = option.properties.country ? option.properties.country : '';
    //   name = option.properties.name ? option.properties.name : '';
    // }

    // if (option.properties && option.properties.type == "country") {
    //   keyword = option.properties.name
    // }
    // else {
    //   if (option.properties.country && option.properties.country) {
    //     keyword = option.properties.name + ', ' + option.properties.country
    //   }
    //   else {
    //     keyword = option.properties.name
    //   }
    // }
    return keyword;
  };

  useEffect(() => {
    if (state.remote) {
      setState({ ...state, location: "Remote Location" });
    } else {
      setState({ ...state, location: null });
    }
  }, [state.remote]);
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <FolderPlusOutline />
        &nbsp;&nbsp;&nbsp;
        {props.repost ? (
          <span>Repost Job Post</span>
        ) : (
            <span>Create Job Post</span>
          )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Title*"
              name="title"
              value={state.title ?? ""}
              onChange={inputChanged}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Job Type*"
              name="job_type"
              value={state.job_type ?? ""}
              onChange={inputChanged}
              fullWidth
              select
              SelectProps={{
                native: true,
              }}
            >
              <option value="part-time">Part-Time</option>
              <option value="full-time">Full-Time</option>
              <option value="annual_salary">Annual Salary</option>
              <option value="pay-based-on-experience">
                Pay Based On Experience
              </option>
            </TextField>
          </Grid>
          {/* <Grid item xs={4}> */}

          {/* </Grid> */}
          <Grid item xs={12}>
            <TextField
              className={classes.textFieldHeight}
              label="Position / Detailed Description"
              name="task"
              value={state.description.task ?? ""}
              onChange={descriptionChanged}
              multiline
              fullWidth
              rows="1"
              rowsMax="5"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="External Application Link*"
              name="external_application_href"
              onChange={inputChanged}
              value={state.external_application_href ?? ""}
              fullWidth
            />
            <span style={{ fontSize: "10px" }}>
              A URL must include http or https inorder to be
              considered valid. eg. https://www.example.com
            </span>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginTop: 0, padding: 0 }}
              // style={{ marginTop: "10px" }}
              control={
                <Checkbox
                  checked={state.checkedB}
                  onChange={handleChangeRemote}
                  name="remote"
                  color="primary"
                // inputProps={{ margin: 30px }}
                />
              }
              label="Remote Job Location or WFH / Work From Home"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">
              Selected Location: {state.location ?? "None"}
            </Typography>
            {/* <Geosuggest
                placeholder="Type an Address"
                initialValue={state.location ?? ''}
                onSuggestSelect={onSuggestSelect}
                onChange={changeSuggest}
              /> */}
            <Grid item xs={12}>
              {/* <Autocomplete
                  label='Type an Address'
                  name='Type an Address'
                  onChange={handleSearch}
                  type="search"
                  // value={state.external_application_href ?? ''}
                  fullWidth
                /> */}
              <Autocomplete
                id="select-on-focus"
                name="Type an Address"
                // selectOnFocus
                freeSolo

                onInputChange={handleSearch}
                onChange={handleSelectSuggestion}
                options={suggestionList}
                getOptionLabel={handleOptions}
                filterOptions={(options, state) => options}
                // style={{ width: 300 }}
                // value=""
                // defaultValue={{ properties: { name: state.location }, geometry: { coordinates: [state?.geometry?.coordinates] } }}
                // value={state.location ?? ''}
                renderInput={(params) => (
                  <>
                    <TextField {...params} label="Type an Address*" />{" "}
                  </>
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
                <TextField id="standard-search" label="Search field" type="search" onChange={handleSearch} />
              </Grid> */}
            <span style={{ fontSize: "10px" }}>
              If you are NOT posting a WFH / Work From Home or Remote Job you
              MUST INCLUDE A COMPLETE ADDRESS. If you do not choose one or the
              other or if you leave this area blank or incomplete, your post
              will not appear in search results and/or on the map
            </span>
          </Grid>

          <Grid item xs={6}>
            <KeyboardDatePicker
              label="Expire Date*"
              format="MM/dd/yyyy"
              onChange={handleDateChange("end_time")}
              value={state.end_time}
              fullWidth
              minDate={new Date(state.start_time).setHours(
                new Date(state.start_time).getHours() + 1
              )}
              maxDate={new Date(state.start_time).setFullYear(
                new Date(state.start_time).getFullYear() + 1
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Benefits"
              name="benefits"
              onChange={inputChanged}
              fullWidth
              value={state.benefits ?? ""}
              multiline
              rows="1"
              rowsMax="5"
            //  
            // fullWidthx
            // rows="3"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={state.job_type !== "pay-based-on-experience" ? 4 : 6}
          >
            <TextField
              label="Category*"
              name="category"
              onChange={inputChanged}
              fullWidth
              value={state.category ?? ""}
              select
              SelectProps={{
                native: true,
              }}
            >
              <option value="Please Select Category">Select a Category</option>
              {categories.map(mapCategories)}
            </TextField>
          </Grid>

          <Grid
            item
            xs={12}
            sm={state.job_type !== "pay-based-on-experience" ? 4 : 6}
          >
            <TextField
              label="Attire"
              name="attire"
              onChange={descriptionChanged}
              fullWidth
              value={state.description.attire ?? ""}
              multiline
              rows="1"
              rowsMax="5"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {state.job_type == "part-time" && (
              <TextField
                label="PAY PER HOUR / USD"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={state.hourly_rate ?? props.job.hourly_rate ?? ""}
                name="hourly_rate"
                onChange={inputChanged}
                fullWidth
              />
            )}
            {state.job_type == "full-time" && (
              <TextField
                label="PAY PER HOUR / USD"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={state.hourly_rate ?? props.job.hourly_rate ?? ""}
                name="hourly_rate"
                onChange={inputChanged}
                fullWidth
              />
            )}
            {state.job_type == "annual_salary" && (
              <TextField
                label="SALARY / USD"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={state.annual_salary ?? props.job.annual_salary ?? ""}
                name="annual_salary"
                onChange={inputChanged}
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <div
        style={{
          padding: "0px 23px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* <Grid style={{ marginLeft: "10px" }}> */}

        <Typography variant="caption" style={{ paddingTop: "10px" }}>
          {/* * - Recommended, but not a required field. */}* - Required fields
        </Typography>
        {/* </Grid> */}
      </div>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button color="primary" onClick={createJobPost}>
          Post
          {loading && (
            <React.Fragment>
              &nbsp;
              <CircularProgress size={20} />
            </React.Fragment>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withAlertAndProgressDialog(CreateJobDialog);
